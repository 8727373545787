import React from "react";

const DiiaBanner = () => {
    return (
        <div className="diia-banner-wrapper">
            <div className="diia-banner-container">
                <img src={require("../../../assets/img/logos/diia.svg")} alt="diiaLogoBanner" />
                Зручне оформлення полісу з автозаповненням даних через Дію
            </div>
        </div>
    );
};

export default DiiaBanner;