import React, {useEffect, useState} from "react";
import DataOrder from "./DataOrder";
import useForm from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
  getCalculatorAutoTypeDTO,
  getPrivilege,
  getSelectedAmountItem,
  getSelectedItem,
  getDiscountPercentage,
  getVehicleType,
  getVehicleCategory,
  getPeriod,
  getCashback,
  getPolicyId,
  getIsForeign,
  getIsTaxi,
  getIsPromocode,
  getCoverageArea,
  getAnalyticsId
} from "../../redux-data/insurance/insuranceSelector";
import {
  getInsuranceType, 
  checkValue,
  fetchVehicleData, 
  validateINNCode, 
  convertINNToBirthDate, 
  validatePhoneNumber,
  validateOrderData,
  checkOrderDiscount,
  checkOrderCoverageArea,
  checkOrderCashback,
  checkOrderPeriod,
  findDBVehicleModel,
  checkVehicleBrand,
  findVehicleModel,
  fetchVehicleModelByBrandID,
  handleInsuranceNav,
  navToHomePage,
  findVehicle,
  fetchVehicleDataByParams,
  checkEuaDocumentByDiia,
  checkGreencardINGOCompany,
  checkAnalyticsData,
  checkRequestStatus,
  checkEuaDocumentByBankID,
  isDiiaDocumentServiceSelected,
  isBankIDDocumentServiceSelected,
  findBankIDCustomerDocument,
  findBankIDCustomerAddress,
  validatePhoneNumberFormat
} from "../../utils/utilsGeneral";
import {patternEmail} from "../../utils/getInstanceError";
import CustomField from "./CustomField";
import PassportPartForm from "./PassportPartForm";
import {
  getCorrectDate, 
  getMaxDate, 
  getInsuranceStartFromDate, 
  getCurrentDateTime, 
  validateDBDateTime, 
  getGreencardExpirationDate, 
  getEpolicyExpirationDate, 
  validateDBDate,
  validateAndFormatDateTime
} from "../../utils/utilsDate";
import IDCardPartForm from "./IDCardPartForm";
import DriverLicensePartForm from "./DriverLicensePartForm";
import InsurancePeriod from "./InsurancePeriod";
import {useStepsContext} from "../../utils/StepsProvider";
import useReactRouter from "use-react-router";
import {
  createOfferFetch,
  getCheckModel,
  getOfferData,
  getOfferId,
  getOfferMark,
  getOfferModel,
  setData,
  setOfferMark,
  setOfferModel,
  setOfferDontHaveInn,
  validateOfferStatus,
  StatusOffer,
  getOfferDocumentType,
  getOfferDocumentServiceSelected,
  getOfferBankIDServiceInitialized,
  setOfferBankIdServiceInitialized,
  getOfferBankIDServiceAuthorized,
  setOfferBankIdServiceAuthorized,
  getOfferIsTestBankIDDomain,
  setOfferFourStepId,
  getOfferFourStepID
} from "../../redux-data/offer/offerReducer";
import CustomSelect from "../common/CustomSelect";
import {getVehicleData} from "../../redux-data/vehicle/vehicleReducer";
import {IVehicleData, IVehicle, IVehicleFound} from "../../redux-data/vehicle/vehicleTypes";
import {setItemId, setReportId} from "../../redux-data/insurance/insuranceReducer";
import {IOfferData, ISimpleData} from "../../redux-data/offer/offerTypes";
import PensionCertificateForm from "./PrivilegeForms/PensionCertificate";
import EPensionCertificateForm from "./PrivilegeForms/EPensionCertificate";
import {setPrivilegeType} from "../../redux-data/user/userReducer";
import {getUserPrivilegeType} from "../../redux-data/user/userSelector";
import DisabilityCertificateForm from "./PrivilegeForms/DisabilityCertificate";
import VeteranCertificateForm from "./PrivilegeForms/VeteranCertificate";
import ChernobylCertificateForm from "./PrivilegeForms/ChernobylCertificate";
import webConfig from "../../config";
import {getKindByType} from "../../core/typeAuto";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {diiaDocuments, euaDocuments, privilegeTypes, bankIDDocuments} from "../../mock-data/insuranceTypes";
import {ICustomer, ICustomerFound} from "../../redux-data/user/userType";
import Switch from "react-switch";
import CustomLoader from "../Forms/common/CustomLoader";
import InfoSpoiler from "../Forms/common/InfoSpoiler";
import {IGreencardCashback} from "../../typings/IDataOffers";
import ExternalPassportPartForm from "./ExternalPassportPartForm";
import {getVehicleRegLocation} from "../../redux-data/city/cityReducer";
import {IReport} from "../../redux-data/report/reportTypes";
import DiiaComponent from "../common/DiiaComponent";
import _ from "lodash";
import isWebConfigOn from "to-boolean";
import {useHistory} from "react-router-dom";
import {IDiiaCustomerFound, IDiiaVehicleFound} from "../../typings/IDataDiia";
import PublicModal from "../common/Modals/PublicModal";
import {getCurrentStepID, getCurrentStepType} from "../../redux-data/steps/stepsReducer";
import {insuranceProxyAxiosInstance} from "../../core/configInsuranceProxyAxios";
import {IBankID, BankIDResult, BankIDData, BankIDCustomer} from "../../typings/IBankID";

const address = (name: string) => `address.${name}`;
const transport = (name: string) => `transport.${name}`;
const maxYear = new Date().getFullYear();

const StepFour = ({type}: {type: "epolicy" | "greencard"}) => {
  const period = useSelector(getPeriod);
  const policyFranchiseID = useSelector(getPolicyId);
  const checkModel = useSelector(getCheckModel);
  const mark = useSelector(getOfferMark);
  const model = useSelector(getOfferModel);
  const policy = useSelector(getSelectedItem);
  const policyAmount = useSelector(getSelectedAmountItem);
  const dataInsurance = (useSelector(getOfferData) || {}) as IOfferData;
  const vehicle = (useSelector(getVehicleData) || {}) as IVehicleData;
  const isPrivilege = useSelector(getPrivilege);
  const offerId = useSelector(getOfferId);
  const userPrivilegeType = useSelector(getUserPrivilegeType);
  const calculatorAutoType = useSelector(getCalculatorAutoTypeDTO);
  const greencardVehicleType = useSelector(getVehicleType);
  const greencardVehicleCategory = useSelector(getVehicleCategory);
  const vehicleRegLocationData = useSelector(getVehicleRegLocation);
  const isForeign = useSelector(getIsForeign);
  const isTaxi = useSelector(getIsTaxi);
  const isPromocode = useSelector(getIsPromocode);
  const coverageArea = useSelector(getCoverageArea);
  const selectedPolicyDocument = useSelector(getOfferDocumentType);
  const offerDocumentServiceSelected = useSelector(getOfferDocumentServiceSelected);
  const isBankIDServiceInitialized = useSelector(getOfferBankIDServiceInitialized);
  const isBankIDServiceAuthorized = useSelector(getOfferBankIDServiceAuthorized);
  const analyticsID = useSelector(getAnalyticsId);
  const currentStepID = useSelector(getCurrentStepID);
  const currentStepType = useSelector(getCurrentStepType);
  const isTestBankIDDomain = useSelector(getOfferIsTestBankIDDomain);
  const fourStepID = useSelector(getOfferFourStepID);
  const dispatch = useDispatch();
  const userHistory = useHistory();
  const {match} = useReactRouter();
  const {goTo} = useStepsContext() as any;
  const [startFrom, setStartFrom] = useState(getInsuranceStartFromDate());
  const [expirationDate, setExpirationDate] = useState(type === "epolicy" ? getEpolicyExpirationDate() : getCorrectDate(period.value(new Date(getGreencardExpirationDate()))));
  const [brandOptions, setBrandOptions] = useState<{label: any; value: any;}[]>([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [errorModel, setErrorModel] = useState(false);
  const [errorBrand, setErrorBrand] = useState(false);
  const [errorPrivilege, setErrorPrivilege] = useState(false);
  const [message, setMessage] = useState("");
  const [codeINN, setCodeINN] = useState(true);
  const [customerVehicleNumber, setCustomerVehicleNumber] = useState("");
  const [customerBirthDate, setCustomerBirthDate] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [invalidInnMsg, setInvalidInnMsg] = useState("");
  const [isINNInputDisabled, setINNInputDisabled] = useState(false);
  const [vehicleBrandError, setVehicleBrandError] = useState(false);
  const [vehicleModelError, setVehicleModelError] = useState(false);
  const [privilegeTypeError, setPrivilegeTypeError] = useState(false);
  const [offerSuccessState, setOfferSuccessState] = useState(false);
  const [offerErrorState, setOfferErrorState] = useState(false);
  const [vehicleRegLocation, setVehicleRegLocation] = useState("");
  const [isDBCustomerIdentification, setDBCustomerIdentification] = useState(false);
  const [isDBCustomerFound, setDBCustomerFound] = useState(false);
  const [selectedCustomerFoundData, setSelectedCustomerFoundData] = useState<ICustomerFound>();
  const [isDBVehicleFound, setDBVehicleFound] = useState(false);
  const [selectedVehicleFoundData, setSelectedVehicleFoundData] = useState<IVehicleFound>();
  const [greencardCompanyData, setGreencardCompanyData] = useState<IGreencardCashback>({
    id: 0,
    name: "",
    companyID: 0,
    productCode: 0,
    isCashbackEnabled: false,
    isHidden: false,
    cashbackPercentageValue: 0
  });
  const [isDiiaCustomerFound, setDiiaCustomerFound] = useState(false);
  const [customerDiiaData, setCustomerDiiaData] = useState<IDiiaCustomerFound>();
  const [vehicleDiiaData, setVehicleDiiaData] = useState<IDiiaVehicleFound>();
  const [isBankIDCustomerFound, setBankIDCustomerFound] = useState(false);
  const [customerBankIDData, setCustomerBankIDData] = useState<BankIDCustomer>();
  const [isVerifCustomerCodeError, setVerifCustomerCodeError] = useState(false);
  const [verifCustomerCodeErrorMsg, setVerifCustomerCodeErrorMsg] = useState("");
  const [dbCustomerError, setDBCustomerError] = useState(true);
  const [dbVehicleError, setDBVehicleError] = useState(true);
  const [isBankIDError, setBankIDError] = useState(false);

  const selectedVehicleBrand = {
    value: vehicle.id,
    label: vehicle.name
  };

  const selectedVehicleModel = {
    value: vehicle.id,
    label: vehicle.modelName
  };

  const [vehicleBrandData, setVehicleBrandData] = useState({
    value: mark?.id,
    label: mark?.name
  });

  const [vehicleInfo, setVehicleInfo] = useState<IVehicle>({
    engineVolume: vehicle.engineVolume,
    year: vehicle.year,
    vin: vehicle.vin,
    category: vehicle.kind,
    kind: vehicle.type,
    brand: vehicle.name,
    model: vehicle.modelName,
    modelText: vehicle.brandName,
    stateNumber: vehicle.regNumber,
    dontHaveVIN: vehicle.dontHaveBodyNumber,
    lastModified: vehicle.lastModified ?? vehicle.registrationPlace?.lastModified ?? getCurrentDateTime(),
    autoColor: vehicle.autoColor,
    registrationPlace: vehicle.registrationPlace,
    isForeignReg: isForeign
  });

  const [customerInfo, setCustomerInfo] = useState<ICustomer>({
    phoneNumber: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    innCode: "",
    dontHaveInnCode: false,
    isPrivilege: false,
    documentType: "",
    documentSeries: "",
    documentNumber: "",
    documentIssuedDate: null,
    documentExpirationDate: null,
    documentIssuedBy: "",
    addressSettlementName: "",
    addressStreet: "",
    addressHouseNumber: "",
    addressApartmentNumber: "",
    vehicleStateNumber: "",
    insuranceType: "",
    lastModified: null,
    authCode: null
  });

  const [report, setReport] = useState<IReport>({
    insurerName: "",
    franchise: "",
    price: "",
    discount: "",
    totalAmount: "",
    isPrivilege: false,
    isTaxi: false,
    isPromocode: false,
    isForeign: false,
    creationDate: "",
    fromDate: "",
    expirationDate: "",
    customerPhoneNumber: "",
    vehicleStateNumber: "",
    insuranceType: "",
    isCashback: false,
    cashbackAmount: "",
    policyStatus: "",
    policyPeriod: "",
    policyCoverageArea: "",
    offerID: ""
  });

  const [customersFoundData, setCustomersFoundData] = useState<ICustomerFound[]>();
  const [vehiclesFoundData, setVehiclesFoundData] = useState<IVehicleFound>();
  const {register, handleSubmit, errors, getValues, clearError} = useForm<IOfferData>({
    reValidateMode: "onChange",
    mode: "onChange",
    nativeValidation: false,
    defaultValues: dataInsurance
  });
  const id = (mark && mark.id) || "";
  const currentInsurance = getInsuranceType(match.params["name"]);
  const isDiiaDocServiceSelected = isDiiaDocumentServiceSelected(offerDocumentServiceSelected!);
  const isBankIDDocServiceSelected = isBankIDDocumentServiceSelected(offerDocumentServiceSelected!);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    let vehicleType = getType();
    let vehicleKind = "";

    if (vehicleType) {
        vehicleKind = getKindByType(vehicleType);
        setVehicleInfo((prev) => ({...prev, category: checkValue(vehicleType), kind: checkValue(vehicleKind)}));
    } else {
        vehicleType = greencardVehicleCategory.value;
        vehicleKind = greencardVehicleType.value!;
        setVehicleInfo((prev) => ({...prev, category: checkValue(vehicleType), kind: checkValue(vehicleKind)}));
    }

    if (isBankIDDocServiceSelected && isBankIDServiceInitialized && !isBankIDServiceAuthorized) {
        const bankIDHost = isTestBankIDDomain ? webConfig.bankIDTestHost : webConfig.bankIDHost;

        const storeBankIDData = async () => {
          try {
              const pessDomain = isWebConfigOn(webConfig.isDev) ? webConfig.pessLocal : webConfig.pessWebsite;
              console.log("pessDomain: ", pessDomain);
              
              const bankIDInfo: IBankID = {
                pessDomain: pessDomain,
                insuranceType: currentStepType!,
                isTestBankIDDomain: isTestBankIDDomain!,
                stepID: currentStepID,
                isError: false
              };

              console.log("bankIDInfo: ", bankIDInfo);

              await insuranceProxyAxiosInstance.post("/bankid/add-data", bankIDInfo);
              dispatch(setOfferFourStepId(currentStepID));
              console.log("bankIDHost: ", bankIDHost);
              window.location.href = `${bankIDHost}/DataAccessService/das/authorize?response_type=code&state=${currentStepID}&client_id=${webConfig.bankIDClientID}&redirect_uri=${webConfig.insuranceBaseUrl}/api/bankid/customer/fetch-data`;
              dispatch(setOfferBankIdServiceInitialized(false));
              dispatch(setOfferBankIdServiceAuthorized(true));
          } catch (error: any) {
              console.log("Error: ", error);
              setBankIDError(true);
          }
        };

        storeBankIDData();
    } else if (isBankIDDocServiceSelected && !isBankIDServiceInitialized && isBankIDServiceAuthorized) {
        const fetchBankIDCustomerData = async () => {
          try {
              const bankIDCustomerRes = await insuranceProxyAxiosInstance.get(`/bankid/customer-data/${encodeURIComponent(fourStepID!)}`);
              const bankIDCustomerData: BankIDResult = bankIDCustomerRes.data[0];

              if (bankIDCustomerData) {
                  const bankIDCustomerDocument = JSON.parse(bankIDCustomerData.document);
                  const bankIDCustomer: BankIDData["customer"] = bankIDCustomerDocument.customer;
                  const customerPhoneNumber = validatePhoneNumberFormat(bankIDCustomer.phone);
                  const customerAddress = findBankIDCustomerAddress(bankIDCustomer.addresses, "juridical") ?? findBankIDCustomerAddress(bankIDCustomer.addresses, "factual") ?? undefined;
                  const customerDocument = findBankIDCustomerDocument(bankIDCustomer.documents, selectedPolicyDocument!);
                  const customerDateModification = validateAndFormatDateTime(bankIDCustomer.dateModification);
                  setBankIDCustomerFound(true);
                  setCustomerBankIDData({
                    firstName: bankIDCustomer.firstName,
                    lastName: bankIDCustomer.lastName,
                    middleName: bankIDCustomer.middleName,
                    phone: customerPhoneNumber,
                    email: bankIDCustomer.email,
                    birthDay: bankIDCustomer.birthDay,
                    inn: bankIDCustomer.inn,
                    dateModification: customerDateModification,
                    signature: bankIDCustomer.signature,
                    document: customerDocument,
                    address: customerAddress
                  });
                  setCustomerPhone(customerPhoneNumber);
                  setCustomerBirthDate(bankIDCustomer.birthDay);
                  setVehicleRegLocation(customerAddress?.city ? customerAddress.city.trim() : "");
                  setCustomerInfo((prev) => ({...prev, firstName: bankIDCustomer.firstName, lastName: bankIDCustomer.lastName, middleName: bankIDCustomer.middleName, innCode: bankIDCustomer.inn, email: bankIDCustomer.email, birthDate: bankIDCustomer.birthDay, 
                                              phoneNumber: customerPhoneNumber, dontHaveInnCode: false, isPrivilege: false, addressSettlementName: customerAddress?.city ?? "", addressStreet: customerAddress?.street ?? "", 
                                              addressHouseNumber: customerAddress?.houseNo ?? "", addressApartmentNumber: customerAddress?.flatNo ?? "", documentSeries: customerDocument?.series ?? "", documentNumber: customerDocument?.number ?? "", 
                                              documentIssuedBy: customerDocument?.issue ?? "", documentIssuedDate: customerDocument?.dateIssue ?? "", documentExpirationDate: customerDocument?.dateExpiration ?? "", lastModified: customerDateModification}));
                  setReport((prev) => ({...prev, customerPhoneNumber: customerPhoneNumber}));
                  deleteBankIDCustomerData();
              } else {
                  setBankIDCustomerFound(false);
              }
          } catch (error: any) {
              setBankIDError(true);
          }
        };

        fetchBankIDCustomerData();
    }

    if (isForeign) {
        setVehicleInfo((prev) => ({...prev, isForeignReg: true, registrationPlace: {
          placeCode: 0,
          zone: 0,
          nameFull: "",
          id: 0,
          country: "",
          name: "",
          nameRus: "",
          nameFullRus: "",
          cdbMtibu: false,
          cdbMtibuCode: 0,
          lastModified: ""
        }}));
    } else {
        setVehicleInfo((prev) => ({...prev, isForeignReg: false, registrationPlace: {
          placeCode: vehicleRegLocationData?.placeCode ? vehicleRegLocationData.placeCode : vehicleRegLocationData!.place_code,
          zone: vehicleRegLocationData!.zone,
          nameFull: vehicleRegLocationData?.nameFull ? vehicleRegLocationData.nameFull : vehicleRegLocationData!.name_full,
          id: vehicleRegLocationData!.id,
          country: vehicleRegLocationData!.country,
          name: vehicleRegLocationData!.name,
          nameRus: vehicleRegLocationData?.nameRus ? vehicleRegLocationData.nameRus : vehicleRegLocationData!.name_rus,
          nameFullRus: vehicleRegLocationData?.nameFullRus ? vehicleRegLocationData.nameFullRus : vehicleRegLocationData!.name_full_rus,
          cdbMtibu: vehicleRegLocationData?.cdbMtibu ? vehicleRegLocationData.cdbMtibu : vehicleRegLocationData!.cdb_mtibu,
          cdbMtibuCode: vehicleRegLocationData?.cdbMtibuCode ? vehicleRegLocationData.cdbMtibuCode : vehicleRegLocationData!.cdb_mtibu_code,
          lastModified: vehicleRegLocationData?.lastModified ? vehicleRegLocationData.lastModified : vehicleRegLocationData!.last_modified
        }}));
    }

    setCustomerInfo((prev) => ({...prev, insuranceType: checkValue(type), isPrivilege: validateOrderData(isPrivilege), documentType: checkValue(euaDocument), vehicleStateNumber: checkValue(vehicle.regNumber)}));
    setReport((prev) => ({...prev, insurerName: policy!.name, price: policyAmount!.amount.toFixed(2), discount: checkOrderDiscount(type, policyAmount).toFixed(0), insuranceType: checkValue(type), isTaxi: validateOrderData(isTaxi), 
                         isPromocode: validateOrderData(isPromocode), isForeign: validateOrderData(isForeign), isPrivilege: validateOrderData(isPrivilege), vehicleStateNumber: checkValue(vehicle.regNumber), franchise: `${policyAmount!.franchise} грн`, 
                         isCashback: policyAmount!.isCashback, totalAmount: policyAmount!.discountedAmount.toFixed(2), cashbackAmount: checkOrderCashback(policyAmount), policyCoverageArea: checkOrderCoverageArea(type, coverageArea), 
                         policyPeriod: checkOrderPeriod(type, period), policyStatus: validateOfferStatus(StatusOffer.DRAFT), creationDate: validateDBDateTime(getCurrentDateTime()), fromDate: validateDBDate(startFrom), 
                         expirationDate: validateDBDate(expirationDate)}));

    if (type === "epolicy" && vehicleRegLocationData) {
        const settlementName = vehicleRegLocationData?.nameFull ? vehicleRegLocationData?.nameFull.split(",")[0] : vehicleRegLocationData?.name_full.split(",")[0];
        setVehicleRegLocation(settlementName);
        setCustomerInfo((prev) => ({...prev, addressSettlementName: settlementName}));
    }

    if (type === "greencard") {
        const fetchGreencardCompanyData = async () => {
          try {
              const res = await dbAxiosInstance.get(`/greencard/fetch-company-data/${encodeURIComponent(policy!.data[0].productCode)}/${encodeURIComponent(policy!.companyId)}`);
              const dbGreencardData = res.data;

              if (dbGreencardData) {
                  if (dbGreencardData.length > 0) {
                      setGreencardCompanyData({id: dbGreencardData[0].id, name: dbGreencardData[0].name, companyID: dbGreencardData[0].company_id, productCode: dbGreencardData[0].product_code, isCashbackEnabled: dbGreencardData[0].is_cashback_enabled, 
                                              cashbackPercentageValue: dbGreencardData[0].cashback_percentage, isHidden: dbGreencardData[0].is_hidden});
                  } else {
                      setGreencardCompanyData((prev) => ({prev, id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0}));
                  }
              } else {
                  setGreencardCompanyData((prev) => ({prev, id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0}));
              }
          } catch (error: any) {
              setGreencardCompanyData((prev) => ({prev, id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0}));
          }
        };

        fetchGreencardCompanyData();
    }
  }, []);

  useEffect(() => {
    if (Number(id)) {
        const handle = async () => {
          const vehicleModels = await fetchVehicleModelByBrandID(id);
          const models = vehicleModels.map((vehicleModel) => {
            const parsedModel = JSON.parse(vehicleModel.auto_maker);
            return {
              label: vehicleModel.name,
              value: vehicleModel.id,
              mtsbuId: parsedModel.cdb_mtibu_code
            }
          });
          setModelOptions(models);
        };
        handle();
    }
  }, [id]);

  useEffect(() => {
    if (offerId !== null && !report.offerID) {
        setReport((prev) => ({...prev, offerID: offerId}));
    }

    if (offerId !== null && report.offerID) {
        addReportData(report);
    }

    if (isDiiaCustomerFound && vehicleDiiaData && Object.keys(vehicle).length === 0) {
        validateDiiaVehicleData();
    }
  }, [offerId, report, isDiiaCustomerFound, vehicleDiiaData]);

  useEffect(() => {
    if (mark && model) {
        setVehicleInfo((prev) => ({...prev, brand: mark.name, model: model.label, modelText: `${mark?.name} ${model?.label}`}));
    }
  }, [mark, model]);

  useEffect(() => {
    const fetchBrands = async () => {
      const vehicleBrands = await dbAxiosInstance.get("/all-vehicle/brands");
      const filteredBrands = vehicleBrands.data.filter((vehicleBrand, index) => index > 0 && JSON.parse(vehicleBrand.auto_maker).name.trim() !== "");
      const brands = filteredBrands.map((vehicleBrand) => {
          const parsedBrand = JSON.parse(vehicleBrand.auto_maker);
          return {
            value: parsedBrand.id,
            label: parsedBrand.name
          }
      });
      setBrandOptions(brands);
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const errorNotifTimer = setTimeout(() => {
        setVerifCustomerCodeError(false);
    }, 5000);

    return () => clearTimeout(errorNotifTimer);
  }, [isVerifCustomerCodeError]);

  const bankIDCustomerDocs = checkGreencardINGOCompany(type, policyFranchiseID) ? [bankIDDocuments[2]] : bankIDDocuments.filter((document) => document.value === selectedPolicyDocument);
  const diiaCustomerDocs = checkGreencardINGOCompany(type, policyFranchiseID) ? [diiaDocuments[2]] : diiaDocuments.filter((document) => document.value === selectedPolicyDocument);
  const euaCustomerDocs = checkGreencardINGOCompany(type, policyFranchiseID) ? [euaDocuments[3]] : euaDocuments.filter((document) => document.value === selectedPolicyDocument);
  const customerDocs = (isDiiaDocServiceSelected ? diiaCustomerDocs : undefined) ?? (isBankIDDocServiceSelected ? bankIDCustomerDocs : undefined) ?? euaCustomerDocs;
  const diiaSelectedDocuments = isDiiaDocServiceSelected && [selectedPolicyDocument, (!isPrivilege && Object.keys(vehicle).length === 0) ? diiaDocuments[3].value : ""].filter((document) => document !== "");
  const euaDocument = (isDiiaDocServiceSelected ? checkEuaDocumentByDiia(selectedPolicyDocument) : undefined) ?? (isBankIDDocServiceSelected ? checkEuaDocumentByBankID(selectedPolicyDocument) : undefined) ?? selectedPolicyDocument;
  const classMark = !mark && errorBrand ? "form-group form-group-error" : "form-group";
  const classModel = !model && errorModel ? "form-group form-group-error" : "form-group";
  const classPrivilege = !userPrivilegeType && errorPrivilege ? "form-group form-group-error" : "form-group";
  const discountPercentage = getDiscountPercentage(policyAmount!.amount, policyAmount!.discountedAmount);

  const validateDiiaVehicleData = async () => {
    let diiaVehicle = await checkVehicleBrand(vehicleDiiaData?.brand);

    if (diiaVehicle) {
        storeVehicleData(diiaVehicle, vehicleDiiaData?.model);
    } else {
        diiaVehicle = await findVehicle(vehicleDiiaData?.brand, vehicleDiiaData?.model);

        if (diiaVehicle) {
            storeVehicleAutoMaker(diiaVehicle);
        } else {
            storeVehicleByBrandAndModel(vehicleDiiaData?.brand, vehicleDiiaData?.model);
        }
    }
  };

  const storeVehicleAutoMaker = (vehicle) => {
    const vehicleAutoMaker = JSON.parse(vehicle.auto_maker);
    setVehicleBrandData((prev) => ({...prev, value: vehicleAutoMaker.id, label: vehicleAutoMaker.name}));        
    const vehicleData = fetchVehicleDataByParams(vehicleAutoMaker.name, vehicleAutoMaker.id, vehicleAutoMaker.cdb_mtibu_code, vehicle.id, vehicle.name);
    dispatch(setOfferMark(vehicleData));
    dispatch(setOfferModel(vehicleData));
  };

  const storeVehicleData = async (vehicle, model) => {
    dispatch(setOfferMark(vehicle));
    setVehicleBrandData((prev) => ({...prev, value: vehicle.id, label: vehicle.name}));
    const models = await fetchVehicleModelByBrandID(vehicle.id);
    const findModel = findDBVehicleModel(models, model);

    if (findModel) {
        dispatch(setOfferModel(findModel));
    } else {
        const vehicleData = fetchVehicleData(vehicle.name, model);  
        dispatch(setOfferModel(vehicleData));
    }        
  };

  const handleModelChange = (model: any) => {
    const findModel = findVehicleModel(modelOptions, model.label);

    if (findModel) {
        dispatch(setOfferModel(model));
        setVehicleInfo((prev) => ({...prev, model: model.label}));
    } else {
        const vehicleData: ISimpleData = {
          name: mark ? mark.name : "",
          id: "",
          mtsbuId: "",
          value: "",
          label: model.label
        };

        dispatch(setOfferModel(vehicleData));
        setVehicleInfo((prev) => ({...prev, model: vehicleData.label}));
    }

    setVehicleModelError(false);    
  };

  const getType = () => {
    let type;

    if (vehicle && vehicle.type) {
        type = vehicle.type;
    } else {
        type = calculatorAutoType;
    }

    return type;
  };

  const handlePrivilegeTypeChange = (type: any) => {
    dispatch(setPrivilegeType(type));

    if (type.value !== "E_PENSION_CERTIFICATE") {
        setCustomerInfo((prev) => ({...prev, documentExpirationDate: null}));
    }

    setCustomerInfo((prev) => ({...prev, documentType: type.value}));
    setErrorPrivilege(false);
    setPrivilegeTypeError(false);
  };
  
  const onSubmit = async (data) => {
    if (!mark) {
        setErrorBrand(true);
        setVehicleBrandError(true);
        return;
    }

    if (!checkModel) {
        setErrorModel(true);
        setVehicleModelError(true);
        return;
    }

    if (isPrivilege && !userPrivilegeType) {
        setErrorPrivilege(true);
        setPrivilegeTypeError(true);
        return setMessage("Тип пільги не вибрано");
    }

    data.startFrom = startFrom;
    data.expirationDate = expirationDate;

    if (userPrivilegeType) {
        data.documentType = userPrivilegeType.value;
    } else {
        data.documentType = euaDocument;
    }

    if (!data.date_of_birth) {
        return setMessage("Дата народженя відсутня");
    }

    const date = new Date();
    const selectDate = new Date(data.date_of_birth);
    date.setFullYear(date.getFullYear() - 18);

    if (selectDate > date) {
        return setMessage("Дата народженя менше 18 років");
    }

    if (data.passport && !data.passport.date) {
        return setMessage("Дата видачі паспорту відсутня");
    }

    if (data.idPassport && !data.idPassport.date) {
        return setMessage("Дата видачі ID карти відсутня");
    }

    if (data.driverLicense && !data.driverLicense.date) {
        return setMessage("Дата видачі прав відсутня");
    }

    if ((data.pensionCertificate && !data.pensionCertificate.date) ||
        (data.disabilityCertificate && !data.disabilityCertificate.date) ||
        (data.veteranCertificate && !data.veteranCertificate.date) ||
        (data.chernobylCertificate && !data.chernobylCertificate.date)) {
        return setMessage("Дата видачі сертифікату відсутня");
    }

    if (data.ePensionCertificate && !data.ePensionCertificate.date_end) {
        return setMessage("Дата закінчення сертифікату відсутня");
    }

    if (isPrivilege && data.transport.engine_volume > Number(webConfig.maxPrivilege)) {
        return setMessage("Об'єм двигуна для пільговиків не може перевищувати 2500");
    }

    if (isPrivilege && vehicle.engineVolume > 0 && data.transport.engine_volume > vehicle.engineVolume) {
        return setMessage("Об'єм двигуна не відповідає обраній категорії ТЗ");
    }

    if (((type === "epolicy") || (type === "greencard" && !isINNInputDisabled)) && !validateINNCode(data.INN)) {
        return setMessage("Невірний ідентифікаційний номер");
    }

    setOfferSuccessState(true);
    addVehicleData(vehicleInfo);
    addCustomerData(customerInfo);
    updateAnalyticsData();
    const res: any = await dispatch(createOfferFetch(type, data));

    if (!res.success) {
        setOfferSuccessState(false);
        setOfferErrorState(true);
    }

    setMessage(res);
  };

  const addVehicleData = async (currentVehicle) => {
    try {
      await dbAxiosInstance.post("/add-vehicle/params", currentVehicle);
    } catch (error: any) {}
  };

  const addCustomerData = async (customer) => {
    try {
      await dbAxiosInstance.post("/add-customer", customer);
    } catch (error: any) {}
  };

  const addReportData = async (report) => {
    try {
        const res = await dbAxiosInstance.post("/add-report/customer", report);
        dispatch(setReportId(res.data.insertID));
        goTo(5);
    } catch (error: any) {}
  };

  const updateAnalyticsData = async () => {
    try {
      const vehicleNumber = vehicle.regNumber ? vehicle.regNumber : customerVehicleNumber;
      const analytics = {insuranceStep: 4, isPhoneNumber: checkAnalyticsData(customerInfo.phoneNumber), isEmail: checkAnalyticsData(customerInfo.email), isVehicle: checkAnalyticsData(vehicleNumber), phoneNumber: customerInfo.phoneNumber, 
                         isCustomer: checkAnalyticsData(customerInfo.firstName), email: customerInfo.email, vehicleStateNumber: vehicleNumber};
      await dbAxiosInstance.put(`/update-analytics/customer/${encodeURIComponent(analyticsID!)}`, analytics);
    } catch (error: any) {}
  };

  const deleteBankIDCustomerData = async () => {
    try {
      await insuranceProxyAxiosInstance.delete(`/bankid/customer-data/remove/${encodeURIComponent(fourStepID!)}`);
    } catch (error: any) {}
  };

  const handleChangePolicy = () => {
    dispatch(setData(getValues()));
    dispatch(setItemId(null));
    goTo(2);
  };
  
  const handleChangeDataOrder = () => {
    dispatch(setData(getValues()));
    dispatch(setItemId(null));
    goTo(1);
  };

  const handleSaveStartDate = (dateFrom: Date, dateTo: Date) => {
    setStartFrom(getCorrectDate(dateFrom));
    setExpirationDate(getCorrectDate(dateTo));
    setReport((prev) => ({...prev, fromDate: validateDBDate(dateFrom), expirationDate: validateDBDate(dateTo)}))
  };

  const handleDocumentChange = (e: any) => {
    setCustomerInfo((prev) => ({...prev, documentType: checkValue(e.target.value)}));

    if (e.target.value === euaDocuments[2].value) {
        setCodeINN(true);
    }
  };

  const handleVehicleNumOnChange = (event) => {
    event.target.value = event.target.value.toUpperCase();
    setCustomerVehicleNumber(event.target.value);
    setVehicleInfo((prev) => ({...prev, stateNumber: checkValue(event.target.value)}));
    setCustomerInfo((prev) => ({...prev, vehicleStateNumber: checkValue(event.target.value)}));
    setReport((prev) => ({...prev, vehicleStateNumber: checkValue(event.target.value)}));
  };

  const handleVinCodeChange = (event) => {
    const vinCode = event.target.value;

    if (vinCode) {
        setVehicleInfo((prev) => ({...prev, vin: checkValue(vinCode), dontHaveVIN: false}));
    } else {
        setVehicleInfo((prev) => ({...prev, vin: "", dontHaveVIN: true}));
    }
  };

  const handleVehicleEngineVolume = (event) => {
    setVehicleInfo((prev) => ({...prev, engineVolume: checkValue(event.target.value)}));
  };

  const handleVehicleYearChange = (event) => {
    setVehicleInfo((prev) => ({...prev, year: checkValue(event.target.value), autoColor: "", lastModified: getCurrentDateTime()}));
  };

  const handleNumberChange = (event) => {
    const customerPhoneNumber = checkValue(event.target.value);
    setCustomerInfo((prev) => ({...prev, phoneNumber: customerPhoneNumber}));
    setReport((prev) => ({...prev, customerPhoneNumber: customerPhoneNumber}));
    runDBCustomerIndentification(customerPhoneNumber, customerInfo.email);
  };

  const handleEmailChange = async (event) => {
    const customerEmail = checkValue(event.target.value);
    setCustomerInfo((prev) => ({...prev, email: customerEmail}));
    runDBCustomerIndentification(customerInfo.phoneNumber, customerEmail);
  };

  const runDBCustomerIndentification = async (phoneNumber, email) => {
    if (phoneNumber.length === Number(webConfig.customerPhoneNumLength) && patternEmail.test(email) && !isDiiaDocServiceSelected && !isBankIDDocServiceSelected) {
        const res = await dbAxiosInstance.get(`/find-customer/${encodeURIComponent(validatePhoneNumber(phoneNumber))}/${encodeURIComponent(email)}/${encodeURIComponent(currentInsurance.value)}/${encodeURIComponent(validateOrderData(isPrivilege))}/${encodeURIComponent(euaDocument!)}`);
        const {rows, id} = res.data;

        if (rows > 0 && id > 0) {
            setDBCustomerIdentification(true);
            setCustomerInfo((prev) => ({...prev, id: id}));
            setDBCustomerFound(false);
            setDBCustomerError(true);
            setDBVehicleError(true);
            updateCustomerAuthData(id);
        }
    }
  };

  const fetchDBCustomer = async (phoneNumber, email) => {
    setDBCustomerIdentification(false);
    setDBCustomerFound(false);
    setCustomersFoundData(undefined);
    setSelectedCustomerFoundData(undefined);

    if (phoneNumber.length === Number(webConfig.customerPhoneNumLength) && patternEmail.test(email)) {
        try {
          const res = await dbAxiosInstance.get(`/customer/${encodeURIComponent(validatePhoneNumber(phoneNumber))}/${encodeURIComponent(email)}/${encodeURIComponent(currentInsurance.value)}/${encodeURIComponent(validateOrderData(isPrivilege))}/${encodeURIComponent(euaDocument!)}`);
          const dbCustomerData = res.data;

          if (dbCustomerData) {
              if (dbCustomerData.length > 0) {
                  setCustomersFoundData(dbCustomerData.map((customer) => ({
                    ...customersFoundData,
                    label: `${customer.last_name} ${customer.first_name} ${customer.middle_name}`,
                    value: customer.id,
                    description: customer.vehicle_state_number,
                    disabled: false,
                    id: customer.id,
                    phoneNumber: customer.phone_number,
                    email: customer.email,
                    firstName: customer.first_name,
                    middleName: customer.middle_name,
                    lastName: customer.last_name,
                    birthDate: validateDBDate(customer.birth_date),
                    innCode: customer.inn_code,
                    dontHaveInnCode: customer.dont_have_inn_code,
                    isPrivilege: customer.is_privilege,
                    documentType: customer.document_type,
                    documentSeries: customer.document_series,
                    documentNumber: customer.document_number,
                    documentIssuedDate: validateDBDate(customer.document_issued_date),
                    documentExpirationDate: validateDBDate(customer.document_expiration_date),
                    documentIssuedBy: customer.document_issued_by,
                    addressSettlementName: customer.address_settlement_name,
                    addressStreet: customer.address_street,
                    addressHouseNumber: customer.address_house_number,
                    addressApartmentNumber: customer.address_apartment_number,
                    vehicleStateNumber: customer.vehicle_state_number,
                    insuranceType: customer.insurance_type,
                    lastModified: validateDBDateTime(customer.last_modified)
                  })));
                  setDBCustomerFound(true);
              } else {
                  setDBCustomerFound(false);
              }
          } else {
              setDBCustomerFound(false);
          }
        } catch (error: any) {
          setDBCustomerFound(false);
          setDBVehicleFound(false);
        }
    } else {
        setDBCustomerFound(false);
        setDBVehicleFound(false);
    }
  };

  const updateCustomerAuthData = async (id: number) => {
    try {
      const authData = {customerID: id};
      const customerAuthCodeRes = await dbAxiosInstance.put("/customer/verification-code/generate", authData);

      if (checkRequestStatus(customerAuthCodeRes.status)) {
          await dbAxiosInstance.post("/customer/send-verification/code", authData);
          setVerifCustomerCodeError(false);
          setVerifCustomerCodeErrorMsg("");
      } else {
          setVerifCustomerCodeError(true);
          setVerifCustomerCodeErrorMsg("Не вдалось відправити код підтвердження!");
      }
    } catch (error: any) {}
  };

  const clearCustomerAuthData = async (id: number) => {
     try {
      await dbAxiosInstance.put("/customer/verification-code/clear", {customerID: id});
    } catch (error: any) {}
  };

  const handleLastNameChange = (event) => {
    setCustomerInfo((prev) => ({...prev, lastName: checkValue(event.target.value)}));
  };
  
  const handleNameChange = (event) => {
    setCustomerInfo((prev) => ({...prev, firstName: checkValue(event.target.value)}));
  };

  const handleMiddleNameChange = (event) => {
    setCustomerInfo((prev) => ({...prev, middleName: checkValue(event.target.value)}));
  };

  const handleBirthDateChange = (name, date) => {
    setCustomerInfo((prev) => ({...prev, birthDate: validateDBDate(date)}));
    clearError(name);
  };

  const handleInnCodeChange = (event) => {
    const innCode = event.target.value;
    
    if (innCode.length === Number(webConfig.customerINNCodeLength)) {
        if (validateINNCode(innCode)) {
            const {year, month, day} = convertINNToBirthDate(innCode);
            const birthDateFromInn = validateDBDate(`${year}-${month}-${day}`);
            setCustomerBirthDate(birthDateFromInn);
            setInvalidInnMsg("");
            setMessage("");
            setCustomerInfo((prev) => ({...prev, innCode: checkValue(innCode), dontHaveInnCode: false, birthDate: birthDateFromInn}));
            dispatch(setOfferDontHaveInn(false));
        } else {
            setInvalidInnMsg("Невірний ідентифікаційний номер");
        }
    } else {
        setInvalidInnMsg("");
    }
  };

  const handleDocumentIssuedDateChange = (name, date) => {
    setCustomerInfo((prev) => ({...prev, documentIssuedDate: checkValue(date)}));
    clearError(name);
  };

  const handleDocumentSeriesChange = (event) => {
    setCustomerInfo((prev) => ({...prev, documentSeries: checkValue(event.target.value)}));
  };

  const handleDocumentNumberChange = (event) => {
    setCustomerInfo((prev) => ({...prev, documentNumber: checkValue(event.target.value)}));
  };

  const handleDocumentIssuedByChange = (event) => {
    setCustomerInfo((prev) => ({...prev, documentIssuedBy: checkValue(event.target.value)}));
  };

  const handleSettlementNameChange = (event) => {
    setCustomerInfo((prev) => ({...prev, addressSettlementName: checkValue(event.target.value)}));
  };

  const handleAddressStreetChange = (event) => {
    setCustomerInfo((prev) => ({...prev, addressStreet: checkValue(event.target.value)}));
  };

  const handleAddressHouseNumberChange = (event) => {
    setCustomerInfo((prev) => ({...prev, addressHouseNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
  };

  const handleAddressApartmentNumberChange = (event) => {
    setCustomerInfo((prev) => ({...prev, addressApartmentNumber: checkValue(event.target.value)}));
  };

  const handleDocumentExpirationDateChange = (name, date) => {
    setCustomerInfo((prev) => ({...prev, documentExpirationDate: checkValue(date)}));
    clearError(name);
  };

  const handleCustomerFoundDataChange = async (data) => {
    setSelectedCustomerFoundData(data);
    setCustomerBirthDate(validateDBDate(data.birthDate));
    setINNInputDisabled(data.dontHaveInnCode);
    dispatch(setOfferDontHaveInn(data.dontHaveInnCode));
    setCustomerInfo({phoneNumber: data.phoneNumber, email: data.email, firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, birthDate: validateDBDate(data.birthDate), innCode: data.innCode, dontHaveInnCode: data.dontHaveInnCode, 
                    isPrivilege: data.isPrivilege, documentType: data.documentType, documentSeries: data.documentSeries, documentNumber: data.documentNumber, documentIssuedDate: data.documentIssuedDate, documentExpirationDate: validateDBDate(data.documentExpirationDate), 
                    documentIssuedBy: data.documentIssuedBy, addressSettlementName: data.addressSettlementName, addressStreet: data.addressStreet, addressHouseNumber: data.addressHouseNumber, addressApartmentNumber: data.addressApartmentNumber, 
                    vehicleStateNumber: data.vehicleStateNumber, insuranceType: data.insuranceType, lastModified: data.lastModified, authCode: data.authCode});
    setVehicleRegLocation(data.addressSettlementName ? data.addressSettlementName.trim() : undefined);
    setDBCustomerError(false);

    if (data.vehicleStateNumber && Object.keys(vehicle).length === 0) {
        try {
            setDBVehicleFound(false);
            setVehiclesFoundData(undefined);
            setSelectedVehicleFoundData(undefined);
            setCustomerVehicleNumber("");
            const vehicleData = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(data.vehicleStateNumber.toUpperCase())}`);
            const regPlaceData = await dbAxiosInstance.get(`/vehicle/reg-location/${encodeURIComponent(vehicleData.data[0].reg_place_id)}/${encodeURIComponent(vehicleData.data[0].reg_place_code)}`);
            const dbVehicleData = vehicleData.data;
            const dbRegPlaceData = regPlaceData.data;

            if (dbVehicleData && dbRegPlaceData) {
                if (dbVehicleData.length > 0 && dbRegPlaceData.length > 0) {
                    const customerVehicle = dbVehicleData.map((vehicle) => ({
                      label: `${vehicle.model_text} - ${vehicle.state_number}`,
                      description: vehicle.id,
                      disabled: false,
                      value: vehicle.state_number,
                      engineVolume: vehicle.engine_volume,
                      year: vehicle.year,
                      vin: vehicle.vin,
                      category: vehicle.category,
                      kind: vehicle.kind,
                      brand: vehicle.brand,
                      model: vehicle.model,
                      modelText: vehicle.model_text,
                      stateNumber: vehicle.state_number,
                      dontHaveVIN: vehicle.dont_have_vin,
                      autoColor: vehicle.auto_color,
                      lastModified: vehicle.last_modified,
                      isForeignReg: vehicle.is_foreign,
                      registrationPlace: {
                        id: dbRegPlaceData[0].id,
                        placeCode: dbRegPlaceData[0].place_code,
                        zone: dbRegPlaceData[0].zone, 
                        nameFull: dbRegPlaceData[0].name_full, 
                        country: dbRegPlaceData[0].country,
                        name: dbRegPlaceData[0].name,
                        nameRus: dbRegPlaceData[0].name_rus,
                        nameFullRus: dbRegPlaceData[0].full_rus, 
                        cdbMtibu: dbRegPlaceData[0].cdb_mtibu,
                        cdbMtibuCode: dbRegPlaceData[0].cdb_mtibu_code,
                        lastModified: dbRegPlaceData[0].last_modified
                      }
                    }));
                    setVehiclesFoundData(customerVehicle);
                    setVehicleInfo((prev) => ({...prev, engineVolume: customerVehicle[0].engineVolume, year: customerVehicle[0].year, vin: customerVehicle[0].vin, category: customerVehicle[0].category, kind: customerVehicle[0].kind, 
                                               brand: customerVehicle[0].brand, model: customerVehicle[0].model, modelText: customerVehicle[0].modelText, stateNumber: customerVehicle[0].stateNumber, dontHaveVIN: customerVehicle[0].dontHaveVIN, 
                                               lastModified: customerVehicle[0].lastModified, autoColor: customerVehicle[0].autoColor, registrationPlace: customerVehicle[0].registrationPlace}));
                    setReport((prev) => ({...prev, vehicleStateNumber: customerVehicle[0].stateNumber}));
                    setDBVehicleFound(true);
                } else {
                    setDBVehicleFound(false);
                }
            } else {
                setDBVehicleFound(false);
            }
        } catch (error: any) {
            setDBVehicleFound(false);
        }
    } else {
        setCustomerInfo((prev) => ({...prev, vehicleStateNumber: vehicle.regNumber}));
        setVehicleInfo((prev) => ({...prev, engineVolume: vehicle.engineVolume, year: vehicle.year, vin: vehicle.vin, category: vehicle.type, kind: vehicle.kind, brand: vehicle.name, model: vehicle.modelName, modelText: vehicle.brandName, 
                                   stateNumber: vehicle.regNumber, dontHaveVIN: vehicle.dontHaveBodyNumber, lastModified: vehicle.lastModified, autoColor: vehicle.autoColor, registrationPlace: vehicle.registrationPlace}));
        setReport((prev) => ({...prev, vehicleStateNumber: vehicle.regNumber}));
    }
  };

  const storeVehicleByBrandAndModel = (brand, model) => {
    const vehicleData = fetchVehicleData(brand, model);        
    dispatch(setOfferMark(vehicleData));
    dispatch(setOfferModel(vehicleData));
  };

  const handleVehicleFoundDataChange = async (data) => {
    setSelectedVehicleFoundData(data);
    setCustomerVehicleNumber(data.stateNumber);
    setDBVehicleError(false);
    let vehicleBrand = await checkVehicleBrand(data.brand);

    if (vehicleBrand) {
        storeVehicleData(vehicleBrand, data.model);
    } else {
        vehicleBrand = await findVehicle(vehicleDiiaData?.brand, vehicleDiiaData?.model);

        if (vehicleBrand) {
            storeVehicleAutoMaker(vehicleBrand);
        } else {
            storeVehicleByBrandAndModel(data.brand, data.model);
        }
    }
  };

  const handleInnStateChange = (isInnDisabled) => {
    setINNInputDisabled(isInnDisabled);
    dispatch(setOfferDontHaveInn(isInnDisabled));
    setCustomerInfo((prev) => ({...prev, innCode: !isInnDisabled ? selectedCustomerFoundData?.innCode! : "", dontHaveInnCode: isInnDisabled}));
  };

  const handleBrandChange = (brand: any) => {
    dispatch(setOfferMark({id: brand.value, name: brand.label}));
    setVehicleBrandData((prev) => ({...prev, label: brand.label, value: brand.value}));
    setVehicleBrandError(false);
  };

  const handleDiiaCustomer = (data) => {
    setDBCustomerFound(false);
    setCustomersFoundData(undefined);
    setSelectedCustomerFoundData(undefined);
    setCustomerDiiaData(data);
    setCustomerBirthDate(data.birthDate);
    setCustomerInfo((prev) => ({...prev, firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, documentSeries: data.documentSeries, documentNumber: data.documentNumber, documentIssuedBy: data.documentIssuedBy}));
  };

  const handleDiiaVehicle = (data) => {
    setDBVehicleFound(false);
    setVehiclesFoundData(undefined);
    setSelectedVehicleFoundData(undefined);
    setVehicleRegLocation(data.addressSettlementName ? data.addressSettlementName.trim() : undefined);
    setCustomerVehicleNumber(data.stateNumber);
    setVehicleDiiaData(data);
    setVehicleInfo((prev) => ({...prev, engineVolume: data.engineVolume, autoColor: data.autoColor, dontHaveVIN: false, year: data.year, vin: data.vin, stateNumber: data.stateNumber}));
    setCustomerInfo((prev) => ({...prev, addressSettlementName: data.addressSettlementName, addressStreet: data.addressStreet, addressHouseNumber: data.addressHouseNumber, addressApartmentNumber: data.addressApartmentNumber, 
                               vehicleStateNumber: data.stateNumber, lastModified: getCurrentDateTime()}));
    setReport((prev) => ({...prev, vehicleStateNumber: data.stateNumber}));
  };

  const getVehicleVinCode = () => {
    return (vehicle.vin ? vehicle.vin : undefined) ?? (isDBCustomerFound ? selectedVehicleFoundData?.vin : undefined) ?? (isDiiaCustomerFound ? vehicleDiiaData?.vin : "");
  };

  const handleCustomerVerificationComplete = async (code: string) => {
      const res = await dbAxiosInstance.get(`/verify/customer/code-validation/${encodeURIComponent(code)}/${encodeURIComponent(customerInfo.id!)}`);
      const {rows, id, phoneNumber, email} = res.data;

      if (rows > 0 && id > 0 && phoneNumber.length === Number(webConfig.customerPhoneNumLength) && patternEmail.test(email)) {
          clearCustomerAuthData(id);
          fetchDBCustomer(phoneNumber, email);
          setVerifCustomerCodeError(false);
          setVerifCustomerCodeErrorMsg("");
          window.scrollTo(0, 0);
      } else {
          setDBCustomerIdentification(true);
          setDBCustomerFound(false);
          setVerifCustomerCodeError(true);
          setVerifCustomerCodeErrorMsg("Невірний код підтвердження!");
      }
  };

  return (
      <>
      {offerErrorState ?
        <div className={`${message !== "" ? "error-msg-visible" : "error-msg-hidden"}`}>
          <p dangerouslySetInnerHTML={{__html: message}} />
          <div className="insurance-error-container">
            <button type="button" className="insurance-btn" onClick={() => handleInsuranceNav("epolicy", dispatch, userHistory)}>Автоцивілка</button>
            <button type="button" className="insurance-btn" onClick={() => handleInsuranceNav("greencard", dispatch, userHistory)}>Зелена карта</button>
            <button type="button" className="insurance-btn" onClick={() => navToHomePage(userHistory)}>На головну</button>
          </div>
        </div>
       :
       <>
        {offerSuccessState ?
          <CustomLoader className="status-request" isSquare={true} isInfoOn={true} isLoading={offerSuccessState} infoMsg="Триває опрацювання даних" />
        :
          <div className="row pay-choose pay-policy">
            <div className="col-sm-8">
              <h4>Заповніть дані для договору страхування - Електронна {currentInsurance.label}</h4>
            </div>
          <div className="col-lg-8">
          {
            type === "greencard" && greencardCompanyData.isCashbackEnabled == true && greencardCompanyData.cashbackPercentageValue > 0 &&
            <div className="greencard-cashback-container">
              <InfoSpoiler isDefaultSpoilerTitle={false} spoilerContentVisibility={true} 
                spoilerTitle={`Повертаємо ${greencardCompanyData.cashbackPercentageValue}% вартості полісу на Вашу карту!`} 
                spoilerContent={
                    <ul className="greencard-cashback-list">
                      <li>Після оплати поліса необхідно вказати номер Вашої карти, на яку ви бажаєте отримати свій бонус.</li>
                      <li>Зарахування кешбеку можливе лише на карту українського банку.</li>
                      <li>Кешбек нараховується на карту протягом 3 днів з дати початку дії поліса.</li>
                    </ul> 
                } />
            </div>
          }
          {
            <PublicModal isOpen={isBankIDError} onCLose={() => setBankIDError(!isBankIDError)} type="bankid-error" title="Помилка" />
          }
          {
           isDiiaDocServiceSelected && <DiiaComponent isOpen={isDiiaDocServiceSelected} vehicleData={vehicle} insuranceType={currentInsurance} selectedDocuments={diiaSelectedDocuments} onCustomerFound={(isFound) => setDiiaCustomerFound(isFound)} 
           onSelectedDiiaCustomer={handleDiiaCustomer} onSelectedDiiaVehicle={handleDiiaVehicle} />
          }
          {
            <>
              <PublicModal isOpen={isDBCustomerIdentification} onCLose={() => setDBCustomerIdentification(!isDBCustomerIdentification)} type="customer-identification" title="Вітаємо! Ви вже є клієнтом в нашій системі." 
                onVerifCodeResend={() => updateCustomerAuthData(customersFoundData![0].id!)} onVerifComplete={handleCustomerVerificationComplete} isVerifError={isVerifCustomerCodeError} verifErrorMsg={verifCustomerCodeErrorMsg} 
                onCloseCustomerErrorNotifMsg={() => {
                  setVerifCustomerCodeError(false);
                  setVerifCustomerCodeErrorMsg("");
                }} />
              {isDBCustomerFound && !isDiiaCustomerFound &&
                <div className="pay-policy_form">
                    <div className="row">
                      <div className="col-md-6">
                          <div className="form-group">
                              <label className={dbCustomerError ? "select-error-label" : ""}>Виберіть особисті дані</label>
                              <CustomSelect
                                key={selectedCustomerFoundData?.documentType}
                                options={customersFoundData}
                                handleChange={handleCustomerFoundDataChange}
                                value={selectedCustomerFoundData}
                                isCreatable={false}
                                isDisabled={false}
                                placeholder="Натисніть тут"
                                isError={dbCustomerError}
                              />
                          </div>
                      </div>
                      {isDBVehicleFound && Object.keys(vehicle).length === 0 &&
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className={dbVehicleError ? "select-error-label" : ""}>Виберіть транспортний засіб</label>
                                <CustomSelect
                                  key={selectedVehicleFoundData?.stateNumber}
                                  options={vehiclesFoundData}
                                  handleChange={handleVehicleFoundDataChange}
                                  value={selectedVehicleFoundData}
                                  isCreatable={false}
                                  isDisabled={false}
                                  placeholder="Натисніть тут"
                                  isError={dbVehicleError}
                                />
                            </div>
                        </div>
                      }
                    </div>
                </div>
                }
            </>
          }
          <form id="form_sm" className="pay-policy_form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="row">
              <div className="col-md-6">
                <CustomField
                  type="tel"
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: /[+]\d{12}/,
                      message: "Введіть номер телефону починаючи з +38",
                    },
                    maxLength: {
                      value: 13,
                      message: "Введіть номер телефону починаючи з +38",
                    }
                  })}
                  errors={errors}
                  name="phone_number"
                  label="Номер телефона"
                  placeholder="+38"
                  defaultValue={customerPhone}
                  onChange={handleNumberChange}
                />
              </div>
              <div className="col-md-6">
                <CustomField
                  type="email"
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: patternEmail,
                      message: "Введіть вірний email"
                    }
                  })}
                  errors={errors}
                  name="email"
                  label="Email"
                  defaultValue={(isDBCustomerFound ? selectedCustomerFoundData?.email : undefined) ?? (isBankIDCustomerFound ? customerBankIDData?.email : undefined) ?? ""}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="col-md-12">
                <p className="pay-policy_title">Особисті дані</p>
              </div>
              <div className={`col-md-${type === "epolicy" ? "4" : "6"}`}>
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: type === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                      message: type === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                    }
                  })}
                  errors={errors}
                  name="sername"
                  label="Прізвище"
                  defaultValue={
                    (isDBCustomerFound ? selectedCustomerFoundData?.lastName : undefined) ?? 
                    (isDiiaCustomerFound ? customerDiiaData?.lastName : undefined) ??
                    (isBankIDCustomerFound ? customerBankIDData?.lastName : "")
                  }
                  onChange={handleLastNameChange}
                />
              </div>
              <div className={`col-md-${type === "epolicy" ? "4" : "6"}`}>
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: type === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                      message: type === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                    }
                  })}
                  errors={errors}
                  name="name"
                  label="Iм'я"
                  defaultValue={
                    (isDBCustomerFound ? selectedCustomerFoundData?.firstName : undefined) ?? 
                    (isDiiaCustomerFound ? customerDiiaData?.firstName : undefined) ??
                    (isBankIDCustomerFound ? customerBankIDData?.firstName : "")
                  }
                  onChange={handleNameChange}
                />
              </div>
              {type === "epolicy" && 
                <div className="col-md-4">
                  <CustomField
                    register={register({
                      required: "Це поле обов'язкове",
                      pattern: {
                        value: type === "epolicy" ? /^[а-яa-z-ієї'\s]+$/i : /^[a-z\s]+$/i,
                        message: type === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                      }
                    })}
                    errors={errors}
                    name="middle_name"
                    label="По батькові"
                    defaultValue={
                      (isDBCustomerFound ? selectedCustomerFoundData?.middleName : undefined) ??
                      (isDiiaCustomerFound ? customerDiiaData?.middleName : undefined) ??
                      (isBankIDCustomerFound ? customerBankIDData?.middleName : "")
                    }
                    onChange={handleMiddleNameChange}
                  />
                </div>
              }
              <div className="col-md-6">
                {((type === "epolicy") || (type === "greencard" && !isINNInputDisabled)) &&
                  <CustomField
                    register={register({
                      required: "Це поле обов'язкове",
                      pattern: {
                        value: /\d{10}/,
                        message: "Ідентифікаційний номер містить 10 чисел"
                      },
                      maxLength: {
                        value: 10,
                        message: "Ідентифікаційний номер містить 10 чисел"
                      }
                    })}
                    errors={errors}
                    name="INN"
                    label="Ідентифікаційний номер (ІПН)"
                    defaultValue={
                      (isDBCustomerFound ? selectedCustomerFoundData?.innCode : undefined) ??
                      (isDiiaCustomerFound ? customerDiiaData?.innCode : undefined) ??
                      (isBankIDCustomerFound ? customerBankIDData?.inn : "")
                    }
                    placeholder="1234567890"
                    mask="9999999999"
                    disabled={!codeINN}
                    onChange={handleInnCodeChange}
                  />
                }
                {type === "greencard" &&
                  <div className="switch-container">
                    <label>
                        <Switch onChange={handleInnStateChange} checked={isINNInputDisabled} onColor="#1BA876" />
                        <div className="switch-text">У мене немає ІПН</div>
                    </label>
                  </div>
                }
                {invalidInnMsg !== "" && <div className="item-error">{invalidInnMsg}</div>}
              </div>
              <div className="col-md-6">
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове"
                  })}
                  errors={errors}
                  name="date_of_birth"
                  label="Дата народження"
                  type="date"
                  placeholder="РРРР.ММ.ДД"
                  max={getMaxDate()}
                  autoComplete="off"
                  dateValue={customerBirthDate}
                  onDateChange={handleBirthDateChange}
                />
              </div>

              {isPrivilege ?
                <div className="col-md-12">
                  <div className={classPrivilege}>
                    <label>Тип пільги</label>
                    <CustomSelect
                      options={privilegeTypes}
                      handleChange={handlePrivilegeTypeChange}
                      value={userPrivilegeType}
                      isCreatable={false}
                      isSearchable={false}
                      isDisabled={true}
                      placeholder="Тип пільги"
                      isError={privilegeTypeError}
                    />
                  </div>
                </div>
              : null
              }
              <div className="col-md-12">
                <p className="pay-policy_title">Документ</p>
              </div>

              {!isPrivilege ?
                <>
                  {customerDocs.map((document) => (
                    <div className="col-md-12" key={document.value}>
                      <div className="form-group form-radio">
                        <input
                          onChange={handleDocumentChange}
                          type="radio"
                          id={document.value}
                          value={document.value}
                          checked={document.value === selectedPolicyDocument}
                          hidden
                          disabled={document.disabled}
                          name="documentType"
                          ref={register}
                        />
                        <label htmlFor={document.value}>{document.label}</label>
                      </div>
                    </div>
                  ))}
                  {checkGreencardINGOCompany(type, policyFranchiseID) ?
                      <ExternalPassportPartForm errors={errors} register={register} seriesClass="col-md-4" numberClass="col-md-2" issuedDateClass="col-md-6" issuedByClass="col-md-6" 
                        documentSeriesValue={
                          (isDBCustomerFound && euaDocuments[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : undefined) ??
                          (isDiiaCustomerFound && euaDocuments[3].value === customerDiiaData?.documentType ? customerDiiaData?.documentSeries : undefined) ??
                          (isBankIDCustomerFound && euaDocuments[3].value === customerBankIDData?.document?.type ? customerBankIDData.document.series : "")  
                        } 
                        documentNumberValue={
                          (isDBCustomerFound && euaDocuments[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : undefined) ??
                          (isDiiaCustomerFound && euaDocuments[3].value === customerDiiaData?.documentType ? customerDiiaData?.documentNumber : undefined) ??
                          (isBankIDCustomerFound && euaDocuments[3].value === customerBankIDData?.document?.type ? customerBankIDData.document.number : "")
                        } 
                        documentIssuedDateValue={
                          (isDBCustomerFound && euaDocuments[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : undefined) ??
                          (isDiiaCustomerFound && euaDocuments[3].value === customerDiiaData?.documentType ? customerDiiaData?.documentIssuedDate : undefined) ??
                          (isBankIDCustomerFound && euaDocuments[3].value === customerBankIDData?.document?.type ? customerBankIDData.document.dateIssue : "")
                        } 
                        documentIssuedByValue={
                          (isDBCustomerFound && euaDocuments[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : undefined) ??
                          (isDiiaCustomerFound && euaDocuments[3].value === customerDiiaData?.documentType ? customerDiiaData?.documentIssuedBy : undefined) ??
                          (isBankIDCustomerFound && euaDocuments[3].value === customerBankIDData?.document?.type ? customerBankIDData.document.issue : "")  
                        }  
                        onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} 
                        onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                    :
                      <>
                        {euaDocuments[0].value === euaDocument && <PassportPartForm errors={errors} register={register} seriesClass="col-md-4" numberClass="col-md-2" issuedDateClass="col-md-6" issuedByClass="col-md-6" 
                          documentSeriesValue={
                            (isDBCustomerFound && euaDocuments[0].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[0].value === customerBankIDData?.document?.type ? customerBankIDData.document.series : "")
                          } 
                          documentNumberValue={
                            (isDBCustomerFound && euaDocuments[0].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[0].value === customerBankIDData?.document?.type ? customerBankIDData.document.number : "")
                          } 
                          documentIssuedDateValue={
                            (isDBCustomerFound && euaDocuments[0].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[0].value === customerBankIDData?.document?.type ? customerBankIDData.document.dateIssue : "")
                          }  
                          documentIssuedByValue={
                            (isDBCustomerFound && euaDocuments[0].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[0].value === customerBankIDData?.document?.type ? customerBankIDData.document.issue : "")
                          }  
                          onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} 
                          onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                        }
                        {euaDocuments[1].value === euaDocument && <IDCardPartForm errors={errors} register={register} seriesClass="col-md-3" numberClass="col-md-3" issuedByClass="col-md-3" issuedDateClass="col-md-6" expirationDateClass="col-md-6" 
                          documentSeriesValue={
                            (isDBCustomerFound && euaDocuments[1].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : undefined) ?? 
                            (isDiiaCustomerFound && euaDocuments[1].value === customerDiiaData?.documentType ? customerDiiaData?.documentSeries : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[1].value === customerBankIDData?.document?.type ? customerBankIDData.document.number : "")
                          } 
                          documentNumberValue={
                            (isDBCustomerFound && euaDocuments[1].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[1].value === customerDiiaData?.documentType ? customerDiiaData?.documentNumber : "")
                          } 
                          documentIssuedByValue={
                            (isDBCustomerFound && euaDocuments[1].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[1].value === customerDiiaData?.documentType ? customerDiiaData?.documentIssuedBy : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[1].value === customerBankIDData?.document?.type ? customerBankIDData.document.issue : "")  
                          } 
                          documentIssuedDateValue={
                            (isDBCustomerFound && euaDocuments[1].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[1].value === customerDiiaData?.documentType ? customerDiiaData?.documentIssuedDate : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[1].value === customerBankIDData?.document?.type ? customerBankIDData.document.dateIssue : "")
                          } 
                          documentExpirationDateValue={
                            (isDBCustomerFound && euaDocuments[1].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentExpirationDate : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[1].value === customerDiiaData?.documentType ? customerDiiaData?.documentExpirationDate : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[1].value === customerBankIDData?.document?.type ? customerBankIDData.document.dateExpiration : "")
                          }
                          onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} 
                          onDocumentIssuedDateChange={handleDocumentIssuedDateChange} onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                        }
                        {euaDocuments[2].value === euaDocument && <DriverLicensePartForm errors={errors} register={register} seriesClass="col-md-4" numberClass="col-md-2" issuedDateClass="col-md-6" issuedByClass="col-md-6" 
                          documentSeriesValue={
                            (isDBCustomerFound && euaDocuments[2].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : undefined) ?? 
                            (isDiiaCustomerFound && euaDocuments[2].value === customerDiiaData?.documentType ? customerDiiaData?.documentSeries : "")
                          }
                          documentNumberValue={
                            (isDBCustomerFound && euaDocuments[2].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[2].value === customerDiiaData?.documentType ? customerDiiaData?.documentNumber : "")
                          } 
                          documentIssuedDateValue={
                            (isDBCustomerFound && euaDocuments[2].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[2].value === customerDiiaData?.documentType ? customerDiiaData?.documentIssuedDate : "")  
                          } 
                          documentIssuedByValue={
                            (isDBCustomerFound && euaDocuments[2].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[2].value === customerDiiaData?.documentType ? customerDiiaData?.documentIssuedBy : "")  
                          } 
                          onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} 
                          onDocumentIssuedByChange={handleDocumentIssuedByChange} />
                        }
                        {euaDocuments[3].value === euaDocument && <ExternalPassportPartForm errors={errors} register={register} seriesClass="col-md-4" numberClass="col-md-2" issuedDateClass="col-md-6" issuedByClass="col-md-6" 
                          documentSeriesValue={
                            (isDBCustomerFound && euaDocuments[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : undefined) ?? 
                            (isDiiaCustomerFound && euaDocuments[3].value === customerDiiaData?.documentType ? customerDiiaData?.documentSeries : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[3].value === customerBankIDData?.document?.type ? customerBankIDData.document.series : "")
                          } 
                          documentNumberValue={
                            (isDBCustomerFound && euaDocuments[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[3].value === customerDiiaData?.documentType ? customerDiiaData?.documentNumber : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[3].value === customerBankIDData?.document?.type ? customerBankIDData.document.number : "")  
                          } 
                          documentIssuedDateValue={
                            (isDBCustomerFound && euaDocuments[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[3].value === customerDiiaData?.documentType ? customerDiiaData?.documentIssuedDate : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[3].value === customerBankIDData?.document?.type ? customerBankIDData.document.dateIssue : "")
                          } 
                          documentIssuedByValue={
                            (isDBCustomerFound && euaDocuments[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : undefined) ??
                            (isDiiaCustomerFound && euaDocuments[3].value === customerDiiaData?.documentType ? customerDiiaData?.documentIssuedBy : undefined) ??
                            (isBankIDCustomerFound && euaDocuments[3].value === customerBankIDData?.document?.type ? customerBankIDData.document.issue : "")
                          }  
                          onDocumentSeriesChange={handleDocumentSeriesChange} onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} 
                          onDocumentIssuedDateChange={handleDocumentIssuedDateChange} />
                        }
                      </>
                }
                </>
              : userPrivilegeType ?
                <>
                  {userPrivilegeType.value === privilegeTypes[0].value && (
                    <PensionCertificateForm errors={errors} register={register} seriesClass="col-md-4" numberClass="col-md-4" issuedDateClass="col-md-6" issuedByClass="col-md-6" 
                      documentSeriesValue={privilegeTypes[0].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : ""} 
                      documentNumberValue={privilegeTypes[0].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : ""} 
                      documentIssuedDateValue={privilegeTypes[0].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : ""} 
                      documentIssuedByValue={privilegeTypes[0].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : ""} onDocumentSeriesChange={handleDocumentSeriesChange} 
                      onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} />
                  )}
                  {userPrivilegeType.value === privilegeTypes[1].value && (
                    <EPensionCertificateForm errors={errors} register={register} numberClass="col-md-4" expirationDateClass="col-md-4" 
                      documentNumberValue={privilegeTypes[1].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : ""} 
                      documentExpirationDateValue={privilegeTypes[1].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentExpirationDate : ""} onDocumentNumberChange={handleDocumentNumberChange} 
                      onDocumentExpirationDateChange={handleDocumentExpirationDateChange} />
                  )}
                  {userPrivilegeType.value === privilegeTypes[2].value && (
                    <DisabilityCertificateForm errors={errors} register={register} seriesClass="col-md-4" numberClass="col-md-4" issuedDateClass="col-md-4" issuedByClass="col-md-6" 
                      documentSeriesValue={privilegeTypes[2].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : ""} 
                      documentNumberValue={privilegeTypes[2].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : ""} 
                      documentIssuedDateValue={privilegeTypes[2].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : ""} 
                      documentIssuedByValue={privilegeTypes[2].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : ""} onDocumentSeriesChange={handleDocumentSeriesChange} 
                      onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} />
                  )}
                  {userPrivilegeType.value === privilegeTypes[3].value && (
                    <VeteranCertificateForm errors={errors} register={register} seriesClass="col-md-4" numberClass="col-md-4" issuedDateClass="col-md-4" issuedByClass="col-md-6" 
                      documentSeriesValue={privilegeTypes[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : ""} 
                      documentNumberValue={privilegeTypes[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : ""} 
                      documentIssuedDateValue={privilegeTypes[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : ""} 
                      documentIssuedByValue={privilegeTypes[3].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : ""} onDocumentSeriesChange={handleDocumentSeriesChange} 
                      onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} />
                  )}
                  {userPrivilegeType.value === privilegeTypes[4].value && (
                    <ChernobylCertificateForm errors={errors} register={register} seriesClass="col-md-4" numberClass="col-md-4" issuedDateClass="col-md-4" issuedByClass="col-md-6" 
                      documentSeriesValue={privilegeTypes[4].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentSeries : ""} 
                      documentNumberValue={privilegeTypes[4].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentNumber : ""} 
                      documentIssuedDateValue={privilegeTypes[4].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedDate : ""} 
                      documentIssuedByValue={privilegeTypes[4].value === selectedCustomerFoundData?.documentType ? selectedCustomerFoundData?.documentIssuedBy : ""} onDocumentSeriesChange={handleDocumentSeriesChange} 
                      onDocumentNumberChange={handleDocumentNumberChange} onDocumentIssuedDateChange={handleDocumentIssuedDateChange} onDocumentIssuedByChange={handleDocumentIssuedByChange} />
                  )}
                </>
              :
                <div className="choose_text">Оберіть тип пільги перед заповненням</div>
              }

              <div className="col-md-12">
                <p className="pay-policy_title">Адреса</p>
              </div>
              <div className="col-md-6">
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: type === "epolicy" ? /^[а-яa-z-ієї'.\s]+$/i : /^[a-z\s]+$/i,
                      message: type === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                    }
                  })}
                  errors={errors}
                  name={address("name")}
                  label="Назва населеного пункта"
                  defaultValue={vehicleRegLocation}
                  onChange={handleSettlementNameChange}
                />
              </div>
              <div className="col-md-6">
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: type === "epolicy" ? /^[а-яa-z-ієї.'\s]+$/i : /^[a-z.\s]+$/i,
                      message: type === "epolicy" ? "Тільки символи українського або латинського алфавіту" : "Тільки символи латинського алфавіту"
                    }
                  })}
                  errors={errors}
                  name={address("street")}
                  label="Вулиця"
                  defaultValue={
                    (isDBCustomerFound && selectedCustomerFoundData?.addressStreet ? selectedCustomerFoundData?.addressStreet.trim() : undefined) ??
                    (isDiiaCustomerFound && vehicleDiiaData?.addressStreet ? vehicleDiiaData?.addressStreet.trim() : undefined) ??
                    (isBankIDCustomerFound && customerBankIDData?.address?.street ? customerBankIDData.address.street.trim() : "")
                  }
                  onChange={handleAddressStreetChange}
                />
              </div>
              <div className="col-md-3">
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: /\d/,
                      message: "Тільки цифри"
                    }
                  })}
                  errors={errors}
                  name={address("number_house")}
                  label="Номер будинку"
                  placeholder="12"
                  defaultValue={
                    (isDBCustomerFound && selectedCustomerFoundData?.addressHouseNumber ? selectedCustomerFoundData?.addressHouseNumber.trim() : undefined) ??
                    (isDiiaCustomerFound && vehicleDiiaData?.addressHouseNumber ? vehicleDiiaData?.addressHouseNumber.trim() : undefined) ??
                    (isBankIDCustomerFound && customerBankIDData?.address?.houseNo ? customerBankIDData.address.houseNo.trim() : "")
                  }
                  onChange={handleAddressHouseNumberChange}
                />
              </div>
              <div className="col-md-3">
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: /\d/,
                      message: "Тільки цифри"
                    }
                  })}
                  errors={errors}
                  name={address("number_flat")}
                  label="Номер квартири"
                  placeholder="12"
                  defaultValue={
                    (isDBCustomerFound && selectedCustomerFoundData?.addressApartmentNumber ? selectedCustomerFoundData?.addressApartmentNumber.trim() : undefined) ??
                    (isDiiaCustomerFound && vehicleDiiaData?.addressApartmentNumber ? vehicleDiiaData?.addressApartmentNumber.trim() : undefined) ??
                    (isBankIDCustomerFound && customerBankIDData?.address?.flatNo ? customerBankIDData.address.flatNo.trim() : "")
                  }
                  onChange={handleAddressApartmentNumberChange}
                />
              </div>
              <div className="col-md-12">
                <p className="pay-policy_title">Транспортний засіб</p>
              </div>
              <div className="col-md-6">
                <div className={classMark}>
                  <label>Марка</label>
                  <CustomSelect
                    options={brandOptions}
                    handleChange={handleBrandChange}
                    defaultValue={vehicle ? selectedVehicleBrand : null}
                    isCreatable={true}
                    value={mark ? vehicleBrandData : null}
                    isSearchable={true}
                    placeholder="Honda"
                    isError={vehicleBrandError}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className={classModel}>
                  <label>Модель</label>
                  <CustomSelect
                    options={modelOptions}
                    handleChange={handleModelChange}
                    defaultValue={selectedVehicleModel}
                    isCreatable={true}
                    value={model}
                    isSearchable={true}
                    placeholder="Amaze"
                    isError={vehicleModelError}
                  />
                </div>
              </div>
              {((type === "epolicy" && getType() === "E" || getType() === "F") || (type === "greencard" && greencardVehicleType.code === "F")) && 
                <div className="col-md-6">
                  <CustomField
                    register={register({
                      pattern: {
                        value: /([^IOQioq_\W])/,
                        message: "Поле має хибний формат.\n VIN не може містити латинські букви I, O і Q"
                      }
                    })}
                    errors={errors}
                    name={transport("chassis_number")}
                    label="Номер шасі"
                    placeholder="1234ABCD5678EFG9"
                    defaultValue={getVehicleVinCode()}
                    onChange={handleVinCodeChange}
                  />
                </div>
              }
              {((type === "epolicy" && getType() !== "E" && getType() !== "F") || (type === "greencard" && greencardVehicleType.code !== "F")) &&
              <>
                <div className="col-md-6">
                  <CustomField
                    register={register({
                      required: "Це поле обов'язкове",
                      pattern: {
                        value: /([^IOQioq_\W])/,
                        message: "Поле має хибний формат.\n VIN не може містити латинські букви I, O і Q"
                      }
                    })}
                    errors={errors}
                    name={transport("chassis_number")}
                    label="Номер шасі"
                    placeholder="1234ABCD5678EFG90"
                    defaultValue={getVehicleVinCode()}
                    onChange={handleVinCodeChange}
                  />
                </div>
                <div className="col-md-6">
                  <CustomField
                    register={register({
                      required: "Це поле обов'язкове",
                      pattern: {
                        value: /(^[0-9])/,
                        message: "Заборонено використовувати негативні значення."
                      }
                    })}
                    errors={errors}
                    name={transport("engine_volume")}
                    label="Об'єм двигуна"
                    placeholder="2000"
                    min="0"
                    defaultValue={
                      (vehicle.engineVolume > 0 ? vehicle.engineVolume.toString() : undefined) ??
                      (isDBCustomerFound ? selectedVehicleFoundData?.engineVolume.toString() : undefined) ??
                      (isDiiaCustomerFound ? vehicleDiiaData?.engineVolume.toString() : "")
                    }
                    type="number"
                    onChange={handleVehicleEngineVolume}
                  />
                </div>
              </>
              }
              <div className="col-md-3">
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: /[A-zА-я-І-і-Ї-ї0-9]/,
                      message: "Реєстраційний номер ТЗ не відповідає правилам перевірки"
                    }
                  })}
                  errors={errors}
                  name={transport("global_number")}
                  label="Державний номер"
                  placeholder="AA1234BB"
                  onChange={handleVehicleNumOnChange}
                  defaultValue={
                    (vehicle.regNumber ? vehicle.regNumber : undefined) ?? 
                    ((isDBCustomerFound || isDiiaCustomerFound) ? customerVehicleNumber : "")
                  }
                />
              </div>
              <div className="col-md-3">
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: /\d{4}/,
                      message: `Рік  має бути між 1910 і ${maxYear}.`
                    },
                    min: {
                      value: "1910",
                      message: `Рік  має бути між 1910 і ${maxYear}.`
                    },
                    max: {
                      value: maxYear + "",
                      message: `Рік  має бути між 1910 і ${maxYear}.`
                    }
                  })}
                  errors={errors}
                  name={transport("year_of_issue")}
                  label="Рік випуску"
                  placeholder="2017"
                  defaultValue={
                    (vehicle.year > 0 ? vehicle.year.toString() : undefined) ??
                    (isDBCustomerFound ? selectedVehicleFoundData?.year.toString() : undefined) ??
                    (isDiiaCustomerFound ? vehicleDiiaData?.year.toString() : "")
                  }
                  onChange={handleVehicleYearChange}
                />
              </div>
              <div className="col-md-12">
                <div className="form-group form-check">
                  <input
                    name="isConditions"
                    ref={register({
                      required: true
                    })}
                    type="checkbox"
                    id="check"
                    hidden
                  />
                  <label htmlFor="check">Я приймаю умови публічної оферти, підтверджую достовірність усіх даних</label>
                </div>
                {<div className={`${message !== "" ? "error-msg-visible" : "error-msg-hidden"}`}><p dangerouslySetInnerHTML={{__html: message}} /></div>}
              </div>
            </div>
          </form>
          <InsurancePeriod type={type} onSaveDate={handleSaveStartDate} />
          <div className="search-result_one">
            <div className="search-result_left">Перевiрте Вашi данi, перед оплатою</div>
            <div className="search-result_right">
              <button form="form_sm" type="submit" className="btn btn-link btn-primary btn-buy">
                Оплатити<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="pay-choose_info">
            <DataOrder type={type} handleChangeDataOrder={handleChangeDataOrder} />
            <div className="pay-choose_info-block">
              <p className="subtitle green">Поліс</p>
              <ul className="pay-choose_info-list">
                <li>
                  <p>Назва поліса</p>
                  <div className="font-bold">{policy && policy.name}</div>
                </li>
                <li>
                  <p>Франшиза</p>
                  <div className="font-bold">{policyAmount && policyAmount!.franchise} грн</div>
                </li>
                <li>
                  <p>Вартість</p>
                  {policyAmount && policyAmount.amount > policyAmount.discountedAmount ? 
                    <div className="font-bold">
                      <div className="old-price">{policyAmount.amount.toFixed(2)} грн</div>
                    </div>
                    : 
                    <div className="font-bold">{policyAmount!.amount.toFixed(2)} грн</div>
                  }
                </li>
                {policyAmount && policyAmount.amount > policyAmount.discountedAmount &&
                  <>
                    <li>
                      <p>До оплати</p>
                      <div className="font-bold">{policyAmount!.discountedAmount.toFixed(2)} грн</div>
                    </li>
                    <li>
                      <p>Знижка</p>
                      <div className="font-bold">{discountPercentage.toFixed(0)}%</div>
                    </li>
                  </>
                }
                {
                  type === "greencard" && policyAmount?.isCashback &&
                  <>
                    <li>
                      <p>Кешбек</p>
                      <div className="font-bold">{policyAmount.cashbackPercentage.toFixed(0)}%</div>
                    </li>
                    <li>
                      <p>Сума до повернення на Вашу карту</p>
                      <div className="font-bold">{getCashback(policyAmount.amount, policyAmount.cashbackPercentage).toFixed(2)} ₴</div>
                    </li>
                  </>
                }
              </ul>
              <button onClick={handleChangePolicy} className="btn btn-link btn-edit">
                Змінити
              </button>
            </div>
          </div>
        </div>
        </div>
        }
      </>
     }
    </>
  );
};

export default StepFour;