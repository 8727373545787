import React from "react";
import {ICompanyInsurance} from "../../typings/IDataOffers";
import {useSelector} from "react-redux";
import {getSelectedAmountItem, getDiscountPercentage, getCashback} from "../../redux-data/insurance/insuranceSelector";
import {companyImage, companyImages} from "../../constants/companyImages";
//import im from '../../assets/img/providna.webp';

interface IPolicy extends ICompanyInsurance {
  formId?: string;
  index?: number;
  onBuy?: Function;
  onPay?: Function;
}

const Policy = ({name, data, formId, id, onBuy, onPay, companyId}: IPolicy) => {
  const min = Math.min(...data.map((d) => d.amount));
  const max = Math.max(...data.map((d) => d.amount));

  const minDiscount = Math.min(...data.map((d) => d.discountedAmount));
  const maxDiscount = Math.max(...data.map((d) => d.discountedAmount));
  
  const policy = useSelector(getSelectedAmountItem);
  const price = policy ? policy.amount : min !== max ? `${min.toFixed(2)} - ${max.toFixed(2)}` : min.toFixed(2);
  const policyPrice = policy ? policy.amount : min !== max ? `Вартість від ${min.toFixed(2)} - ${max.toFixed(2)}` : `Вартість ${min.toFixed(2)}`;
  const discountedPrice = policy ? policy.discountedAmount : minDiscount !== maxDiscount ? `Вартість від ${minDiscount.toFixed(2)} - ${maxDiscount.toFixed(2)}` : `Вартість ${minDiscount.toFixed(2)}`;
  const maxPercentage = Math.max(...data.map((d) => getDiscountPercentage(d.amount, d.discountedAmount)));

  const handleClick = () => {
    if (onPay) {
        onPay();
    } else if (onBuy) {
        onBuy(id);
    }
  };
   
  const img = companyImages.find((company: companyImage) => company.id === companyId);
 
  return (
    <div className="search-result_one">
      <figure className="search-result_pic">
        <img src={img ? require(`../../assets/img/logos/${img.key}`) : "\\img\\base.webp"} alt="" />
      </figure>
      <article className="search-result_info">
        <h4>{name}</h4>
        {min > minDiscount || max > maxDiscount ? 
          <div className="search-result_price">
            <div className="old-price">{policyPrice} ₴</div>
            <div>Знижка {maxPercentage.toFixed(0)}%</div>
            <div className="price-item">{discountedPrice} ₴</div>
          </div> 
          : 
          <div className="search-result_price">
            <div className="price-item">{policyPrice} ₴</div>
            {data.map((item, index) =>
              item.isCashback && item.cashbackPercentage > 0 && index < 1 && <div className="cashback-item">Кешбек {item.cashbackPercentage}% на Вашу карту {getCashback(price, item.cashbackPercentage).toFixed(2)} ₴</div>
            )}
          </div>
        }
      </article>
      <div className="search-result_right">
        <button
          form={formId}
          type="submit"
          className="btn btn-link btn-primary btn-buy"
          onClick={handleClick}
        >
          {!formId ? "Вибрати" : "Оплатити"}{" "}
          <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
};

export default Policy;
