import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {checkAuthToken, refreshPage, navToAdminPanel, navToAnalyticsPage, formatPhoneNumber, formatEmail, findInsuranceLabelByType, dialPhoneNumber, validatePhoneNumber} from "../../../utils/utilsGeneral";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {Analytics} from "../../../typings/IAnalytics";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {validateDBDateTime, formatDBDateTime} from "../../../utils/utilsDate";
import InsuranceStep from "../../../components/Forms/common/InsuranceStep";
import AnalyticsMap from "../../../utils/AnalyticsMap";
import LinkComponent from "../../../utils/LinkComponent";
import {ICustomer} from "../../../redux-data/user/userType";
import {IVehicle} from "../../../redux-data/vehicle/vehicleTypes";
import { insuranceTypes } from "../../../mock-data/insuranceTypes";

const AnalyticsDetailsPage = () => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const [authInProgress, setAuthInProgress] = useState(false);
    const {id, externalID, analyticsID} = useParams<{
        id: string; 
        externalID: string;
        analyticsID: string;
    }>();
    const [analyticsItem, setAnalyticsItem] = useState<Analytics>({
        id: 0, 
        customerIP: "", 
        latitude: "", 
        longitude: "", 
        geolocation: "", 
        insuranceStep: "", 
        isPhoneNumber: false, 
        isEmail: false,
        isVehicle: false,
        isCustomer: false,
        phoneNumber: "", 
        email: "",
        vehicleStateNumber: "",
        insurerName: "", 
        franchise: "", 
        insuranceType: "", 
        added: ""
    });
    const [vehicle, setVehicle] = useState<Pick<IVehicle, "id">>({id: 0});
    const [customer, setCustomer] = useState<Pick<ICustomer, "id" | "firstName" | "middleName" | "lastName">>({
        id: 0,
        firstName: "",
        middleName: "",
        lastName: "",
    });
    const [analyticsErrorMsg, setAnalyticsErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        fetchAnalyticsItem();
    }, []);

    const fetchAnalyticsItem = async () => {
        try {
            const analyticsResponse = await dbAxiosInstance.get(`/admin/analytics/${encodeURIComponent(analyticsID)}`);
            const analyticsData = analyticsResponse.data[0];
            setAnalyticsItem({id: analyticsData.id, customerIP: analyticsData.customer_ip, latitude: analyticsData.latitude, longitude: analyticsData.longitude, geolocation: analyticsData.geolocation, insuranceStep: analyticsData.insurance_step,
                              isPhoneNumber: Boolean(analyticsData.is_phone_number), isEmail: Boolean(analyticsData.is_email), isVehicle: Boolean(analyticsData.is_vehicle), isCustomer: Boolean(analyticsData.is_customer), 
                              phoneNumber: analyticsData.phone_number, email: analyticsData.email, vehicleStateNumber: analyticsData.vehicle_state_number, insurerName: analyticsData.insurer_name, franchise: analyticsData.franchise, 
                              insuranceType: analyticsData.insurance_type, added: validateDBDateTime(analyticsData.added)});
            
            if (analyticsData.vehicle_state_number) {
                const vehicleResponse = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(analyticsData.vehicle_state_number.toUpperCase())}`);
                const vehicle = vehicleResponse.data[0];

                if (vehicle) {
                    setVehicle({id: vehicle.id});
                }
            }

            if (analyticsData.phone_number && analyticsData.email && analyticsData.vehicle_state_number && analyticsData.insurance_type) {
                const customerResponse = await dbAxiosInstance.get(`/admin/customer/${encodeURIComponent(validatePhoneNumber(analyticsData.phone_number))}/${encodeURIComponent(analyticsData.email)}/${encodeURIComponent(analyticsData.vehicle_state_number)}/${encodeURIComponent(analyticsData.insurance_type)}`);
                const customer = customerResponse.data[0];;

                if (customer) {
                    setCustomer({id: customer.id, firstName: customer.first_name, middleName: customer.middle_name, lastName: customer.last_name});
                }
            }
        } catch (error: any) {
            setAnalyticsItem({id: 0, customerIP: "", latitude: "", longitude: "", geolocation: "", insuranceStep: "", isPhoneNumber: false, isEmail: false, isVehicle: false, isCustomer: false, phoneNumber: "", email: "", vehicleStateNumber: "", 
                              insurerName: "", franchise: "", insuranceType: "", added: ""});
            setVehicle({id: 0});
            setCustomer({id: 0, firstName: "", middleName: "", lastName: ""});
        }
    };

    const deleteVehicle = async (id) => {
        try {
            await dbAxiosInstance.delete(`/admin/analytics-data/${encodeURIComponent(id)}`);
            dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/analytics`});
        } catch (error: any) {
            const userDataError = error.toJSON();
            setAnalyticsErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setAnalyticsItem({id: 0, customerIP: "", latitude: "", longitude: "", geolocation: "", insuranceStep: "", isPhoneNumber: false, isEmail: false, isVehicle: false, isCustomer: false, phoneNumber: "", email: "", vehicleStateNumber: "", 
                              insurerName: "", franchise: "", insuranceType: "", added: ""});
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                    :
                    <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1>Дані аналітики &#8212; #{analyticsID}</h1>
                            {
                                <div>
                                    <p className="item-title">Геолокація: <span className="font-bold">{analyticsItem.geolocation ? analyticsItem.geolocation : "Невідомо"}</span></p>
                                    <p className="item-title">IP клієнта: <span className="font-bold">{analyticsItem.customerIP ? analyticsItem.customerIP : "Невідомо"}</span></p>
                                    <p className="item-title">Координати (широта / довгота): <span className="font-bold">{analyticsItem.latitude && analyticsItem.longitude ? `${analyticsItem.latitude} / ${analyticsItem.longitude}` : "Невідомо"}</span></p>
                                    <p className="item-title">Крок страхування: <span className="font-bold">{analyticsItem.insuranceStep ? <InsuranceStep step={analyticsItem.insuranceStep} /> : "Невідомо"}</span></p>
                                    <p className="item-title">Номер телефону вказано: <span className="font-bold">{analyticsItem.isPhoneNumber ? <span className="is-analytics-data-available">Так</span> : <span className="is-analytics-data-unavailable">Ні</span>}</span></p>
                                    <p className="item-title">Email вказано: <span className="font-bold">{analyticsItem.isEmail ? <span className="is-analytics-data-available">Так</span> : <span className="is-analytics-data-unavailable">Ні</span>}</span></p>
                                    <p className="item-title">Транспортний засіб вказано: <span className="font-bold">{analyticsItem.isVehicle ? <span className="is-analytics-data-available">Так</span> : <span className="is-analytics-data-unavailable">Ні</span>}</span></p>
                                    <p className="item-title">Дані клієнта вказано: <span className="font-bold">{analyticsItem.isCustomer ? <span className="is-analytics-data-available">Так</span> : <span className="is-analytics-data-unavailable">Ні</span>}</span></p>
                                    {analyticsItem.isCustomer &&
                                        <p className="item-title">ПІБ: {customer.id! > 0 ? <LinkComponent url={`/admin/dashboard/${id}/${externalID}/customer/${analyticsItem.insuranceType}/${validatePhoneNumber(analyticsItem.phoneNumber)}/${customer.id}/view`} 
                                            tooltipMsg="Переглянути дані клієнта" content={
                                                <>
                                                    <span className="font-bold">{analyticsItem.insuranceType === insuranceTypes[0].value ? `${customer.middleName} ${customer.firstName} ${customer.lastName}` : `${customer.lastName} ${customer.firstName}`}</span>
                                                    <i className="fa fa-arrow-up-right-from-square arrow-forward-icon"></i>
                                                </>
                                            } /> : <span className="font-bold">Невідомо</span>}
                                        </p>
                                    }
                                    {analyticsItem.isPhoneNumber && analyticsItem.isEmail &&
                                        <>
                                            <p className="item-title">Номер телефону: <span className="font-bold" dangerouslySetInnerHTML={{__html: dialPhoneNumber(formatPhoneNumber(analyticsItem.phoneNumber))}} /></p>
                                            <p className="item-title">Email: <span className="font-bold" dangerouslySetInnerHTML={{__html: formatEmail(analyticsItem.email)}} /></p>
                                        </>
                                    }
                                    <p className="item-title">Тип страхування: <span className="font-bold">{analyticsItem.insuranceType ? findInsuranceLabelByType(analyticsItem.insuranceType) : "Невідомо"}</span></p>
                                    {analyticsItem.insurerName && analyticsItem.franchise &&
                                        <>
                                            <p className="item-title">Назва страхової компанії: <span className="font-bold">{analyticsItem.insurerName}</span></p>
                                            <p className="item-title">Франшиза: <span className="font-bold">{analyticsItem.franchise}</span></p>
                                        </>
                                    }
                                    {analyticsItem.isVehicle &&
                                        <p className="item-title">Державний номер ТЗ: {vehicle.id! > 0 ? <LinkComponent url={`/admin/dashboard/${id}/${externalID}/view-vehicle/${analyticsItem.vehicleStateNumber}/${vehicle.id}`} tooltipMsg="Переглянути деталі ТЗ" 
                                            content={
                                                <>
                                                    <span className="font-bold">{analyticsItem.vehicleStateNumber}</span><i className="fa fa-arrow-up-right-from-square arrow-forward-icon"></i>
                                                </>
                                            } /> : <span className="font-bold">Невідомо</span>}
                                        </p>
                                    }
                                    <p className="item-title">Додано: <span className="font-bold">{formatDBDateTime(analyticsItem.added)}</span></p>
                                    {analyticsItem.latitude && analyticsItem.longitude && <AnalyticsMap position={[analyticsItem.latitude, analyticsItem.longitude]} />}
                                </div>
                            }
                            <button type="button" className="admin-btn" onClick={() => deleteVehicle(analyticsItem.id)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                            <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                            <button type="button" className="admin-btn" onClick={() => navToAnalyticsPage(dashboardHistory, id, externalID)}>Аналітика</button>
                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                        </div>
                        {!hideErrorNotification && analyticsErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{analyticsErrorMsg}</div>}
                    </>
                }
                </div>
            </div>
        </div>
    );
};

export default AnalyticsDetailsPage;