import React from "react";
import {useDispatch, useSelector} from "react-redux";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import {useMediaQueryContext} from "../../utils/MediaQueryProvider";
import {Redirect, withRouter} from "react-router-dom";
import {useStepsContext} from "../../utils/StepsProvider";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import ReactSteps from "rc-steps";
import {setCurrentStep, setCurrentStepId, setCurrentStepType, getCurrentStep, getCurrentStepID, getCurrentStepType} from "../../redux-data/steps/stepsReducer";
import {getOfferDocumentServiceSelected} from "../../redux-data/offer/offerReducer";
import {isBankIDDocumentServiceSelected} from "../../utils/utilsGeneral";
import {clear} from "../../redux-data/token/tokenReducer";

const Steps = React.memo((props: any) => {
  const {getFirst, getStepById, navigateToStep} = useStepsContext() as any;
  const {mobileView} = useMediaQueryContext() as any;
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);
  const currentStepID = useSelector(getCurrentStepID);
  const currentStepType = useSelector(getCurrentStepType);
  const offerDocumentServiceSelected = useSelector(getOfferDocumentServiceSelected);
  const isBankIDDocServiceSelected = isBankIDDocumentServiceSelected(offerDocumentServiceSelected!);
  const id = props.match.params.id;
  const name = props.match.params.name;
  const step = getStepById(id);
  console.log("offerDocumentServiceSelected: ", offerDocumentServiceSelected, "isBankIDDocServiceSelected: ", isBankIDDocServiceSelected);
  console.log("ID: ", id, "Name: ", name, "Step: ", step);
  console.log("Restore steps1!", "CurrentStep: ", currentStep, "currentStepID: ", currentStepID, "currentStepType: ", currentStepType, "isBankIDDocServiceSelected: ", isBankIDDocServiceSelected);

  if (id && name && step) {
      console.log("Store steps!", "ID: ", id, "Name: ", name, "Step: ", step);
      dispatch(setCurrentStep(step));
      dispatch(setCurrentStepId(id));
      dispatch(setCurrentStepType(name));
  } else if (currentStep && currentStepID && currentStepType && isBankIDDocServiceSelected) {
      console.log("Restore steps2!", "CurrentStep: ", currentStep, "currentStepID: ", currentStepID, "currentStepType: ", currentStepType, "isBankIDDocServiceSelected: ", isBankIDDocServiceSelected);
      navigateToStep(currentStepType, currentStep);
  } else {
      dispatch(clear());
      return <Redirect to={`/${props.match.params["name"]}/${getFirst()}`} />
  }
  
  return (
    <div className={`home-page-non-title`}>
      <section id="calc">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <section className="section-white">
                    <section className="section-osago">
                      <p className="subtitle">Більше ніяких папірців, поліс на e-mail <span className="subtitle-green">за 5 хвилин</span></p>
                      {!mobileView &&
                        <ReactSteps labelPlacement="vertical" direction="horizontal" current={step - 1}>
                          <ReactSteps.Step title="Введіть дані автомобіля" />
                          <ReactSteps.Step title="Виберіть пропозицію" />
                          <ReactSteps.Step title="Виберіть документ" />
                          <ReactSteps.Step title="Заповніть документ" />
                          <ReactSteps.Step title="Сплачуйте онлайн" />
                        </ReactSteps>
                      }
                      {step === 1 && <StepOne type={name} />}
                      {step === 2 && <StepTwo type={name} />}
                      {step === 3 && <StepThree type={name} />}
                      {step === 4 && <StepFour type={name} />}
                      {step === 5 && <StepFive type={name} />}
                    </section>
              </section>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </section>
    </div>
  )
});

export default withRouter(Steps);
