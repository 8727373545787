import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {getMaxDate, getCurrentDateTime, validateDBDate} from "../../../utils/utilsDate";
import CustomField from "../../../components/MainPage/CustomField";
import useForm from "react-hook-form";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {refreshPage, checkValue, navToAdminPanel, checkAuthToken} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {Promocode} from "../../../typings/IDataOffers";

const EditPromocodePage = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const editMaxActivationsCountInput = useRef<HTMLInputElement>(null);
    const {id, externalID, promocode, promocodeID} = useParams<{
        id: string;
        externalID: string;
        promocode: string;
        promocodeID: string;
    }>();
    const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [editPromocode, setPromocodeEdited] = useState<Pick<Promocode, "maxActivations" | "validUntil">>({
        maxActivations: "",
        validUntil: ""
    });
    const [promocodeValidUntilMsg, setPromocodeValidUntilMsg] = useState("");
    const [authInProgress, setAuthInProgress] = useState(false);
    
    const {register, errors, clearError} = useForm({
        reValidateMode: "onChange",
        mode: "onChange"
    });

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchPromocode = async () => {
            try {
                const res = await dbAxiosInstance.get(`/promocode/${encodeURIComponent(promocode.toUpperCase())}/${encodeURIComponent(promocodeID)}`);
                const dbPromocodeData = res.data;

                if (dbPromocodeData) {
                    if (dbPromocodeData.length > 0) {
                        setPromocodeEdited({maxActivations: dbPromocodeData[0].max_activations, validUntil: dbPromocodeData[0].valid_until});
                        setPromocodeValidUntilMsg("Відредагувати дійсний до");
                        setPromocodeErrorMsg("");
                    } else {
                        setPromocodeEdited({maxActivations: "", validUntil: ""});
                        setPromocodeValidUntilMsg("Відредагувати дійсний до");
                        setPromocodeErrorMsg("Немає такого промокоду. Ви можете додати промокод через панель адміністратора.");
                    }
                } else {
                    setPromocodeEdited({maxActivations: "", validUntil: ""});
                    setPromocodeValidUntilMsg("Відредагувати дійсний до");
                    setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити дані промокоду.");
                }
            } catch (error: any) {
                setPromocodeEdited({maxActivations: "", validUntil: ""});
                setPromocodeValidUntilMsg("Відредагувати дійсний до");
                setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити дані промокоду.");
            }
        };

        fetchPromocode();
    }, []);

    useEffect(() => {
        const notifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(notifTimer);
    }, [hideErrorNotification]);

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (parseInt(editPromocode.maxActivations) > 0 && editPromocode.validUntil.length > 0) {
            try {
                const maxActivations = editPromocode.maxActivations;
                const validUntil = validateDBDate(editPromocode.validUntil);
                const edited = getCurrentDateTime();
                await dbAxiosInstance.put(`/promocode/${encodeURIComponent(promocode.toUpperCase())}/${encodeURIComponent(promocodeID)}`, {maxActivations, validUntil, edited});
                setPromocodeErrorMsg("");
                setErrorNotificationHidden(true);
                dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/view-promocode/${promocode}/${promocodeID}/properties`});
            } catch (error: any) {
                const userDataError = error.toJSON();
                setPromocodeErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setPromocodeErrorMsg("Будь ласка, заповніть усі поля, щоб оновити промокод!");
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        setPromocodeEdited({maxActivations: "", validUntil: ""});
        setPromocodeValidUntilMsg("Відредагувати дійсний до");

        if (editMaxActivationsCountInput.current) {
            editMaxActivationsCountInput.current.value = "";
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const navToPromocodesPage = (event) => {
        dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/promocodes`});
    };

    const handleEditInputChange = (event) => {
        setPromocodeEdited((prev) => ({...prev, maxActivations: checkValue(event.target.value)}));
    };

    const handlePromocodeValidUntil = (name, date) => {
        setPromocodeEdited((prev) => ({...prev, validUntil: date}));
        clearError(name);
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати промокод &#8212; {promocode.toUpperCase()}</h1>
                        <div className="edit-dashboard-item-form">
                            <input type="number" name="name" placeholder="Відредагувати максимальну кількість активацій" value={editPromocode.maxActivations} onChange={handleEditInputChange} ref={editMaxActivationsCountInput} />
                            <CustomField register={register({required: "Це поле обов'язкове"})} errors={errors} name="validUntil" label={promocodeValidUntilMsg} type="date" placeholder="РРРР.ММ.ДД" max={getMaxDate()} autoComplete="off" 
                                dateValue={editPromocode.validUntil} onDateChange={handlePromocodeValidUntil} />
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={navToPromocodesPage}>Промокоди</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && promocodeErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default EditPromocodePage;