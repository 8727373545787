import {RootState} from "../index";
import {CreatorReducer} from "../base/base";
import {clear} from "../token/tokenReducer";
import {StepsState, StepsActions} from "./stepsType";

const initState: StepsState = {
  currentStep: 0,
  currentStepId: "",
  currentStepType: null
};

const creator = new CreatorReducer<StepsActions, StepsState>("steps");
creator.addAction("SET_CURRENT_STEP",
((state, action) => {
  return {...state, currentStep: action.payload}
}));
creator.addAction("SET_CURRENT_STEP_ID", 
((state, action) => {
    return {...state, currentStepId: action.payload}
}));
creator.addAction("SET_CURRENT_STEP_TYPE", 
((state, action) => {
    return {...state, currentStepType: action.payload}
}));
creator.addAction(clear, () => {
  return {...initState};
});

const {setCurrentStep, setCurrentStepId, setCurrentStepType} = creator.createActions();

const getCurrentStep = (state: RootState) => state.steps.currentStep;

const getCurrentStepID = (state: RootState) => state.steps.currentStepId;

const getCurrentStepType = (state: RootState) => state.steps.currentStepType;

export {setCurrentStep, setCurrentStepId, setCurrentStepType, getCurrentStep, getCurrentStepID, getCurrentStepType};
export default creator.createReducer(initState);