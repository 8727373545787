import {combineReducers} from "redux";
import {CreatorReducer} from "../base/base";
import serviceOsago from "../../service/serviceOsago";
import {IBaseActions} from "../base/baseTypes";
import {RootState} from "../index";
import {
  getCalculatorAutoType,
  getCoverageArea,
  getIsForeign,
  getIsTaxi, getOtkDate, getPeriod,
  getPrivilege,
  getRightCityId, 
  getVehicleType,
  getAutoKind
} from "./insuranceSelector";
import {getVehicleAutoType} from "../vehicle/vehicleReducer";
import {ICompanyInsurance, IDataOffer, IDataNumberOffer, IDataGreencardOffer} from "../../typings/IDataOffers";
import {getCorrectDate, getFinishToDate, getDay} from "../../utils/utilsDate";
import {clear} from "../token/tokenReducer";
import {IPolicyState} from "./insuranceTypes";
import {getSalePointId} from "../user/userSelector";
import {isVehicleOtkRequired} from "../../utils/utilsGeneral";
//import {setData as setCityData} from "../../redux-data/city/cityReducer";

const init = {
  data: [],
  selectedId: null,
  selectedAmountId: null,
  selectedPolicyId: null,
  selectedPolicy: null,
  reportId: null,
  analyticsId: null,
  error: null,
  isLoading: false
};

interface IOrderActions {
  setDataOrder: any;
}

interface IGreenCardOrderActions {
  setGreenCardDataOrder: any;
}

interface IPolicyActions extends IBaseActions {
  setItemId: any;
  setAmountId: any;
  setPolicyId: any;
  setPolicy: any;
  setReportId: any;
  setAnalyticsId: any;
}

const creatorDataOrder = new CreatorReducer<IOrderActions, {}>("order");
creatorDataOrder.addAction("SET_DATA_ORDER", (state, action) => {
  return {...state, ...action.payload};
});
creatorDataOrder.addAction(clear, (state) => {
  return {};
});

const creatorGreenCardDataOrder = new CreatorReducer<IGreenCardOrderActions, {}>("order");
creatorGreenCardDataOrder.addAction("SET_GREEN_CARD_DATA_ORDER", (state, action) => {
  return {...state, ...action.payload};
});
creatorGreenCardDataOrder.addAction(clear, (state) => {
  return {};
});

const {setDataOrder} = creatorDataOrder.createActions();
const dataOrderReducer = creatorDataOrder.createReducer({});

const {setGreenCardDataOrder} = creatorGreenCardDataOrder.createActions();
const greenCardDataOrderReducer = creatorGreenCardDataOrder.createReducer({});

const creatorPolicy = new CreatorReducer<IPolicyActions, IPolicyState>("policy");
creatorPolicy.addAction("SET_ITEM_ID", (state, action) => {
  return {...state, selectedId: action.payload};
});
creatorPolicy.addAction("SET_AMOUNT_ID", (state, action) => {
  return {...state, selectedAmountId: action.payload};
});
creatorPolicy.addAction("SET_POLICY_ID", (state, action) => {
  return {...state, selectedPolicyId: action.payload};
});
creatorPolicy.addAction("SET_POLICY", (state, action) => {
  return {...state, selectedPolicy: action.payload};
});
creatorPolicy.addAction("SET_REPORT_ID", (state, action) => {
  return {...state, reportId: action.payload}
});
creatorPolicy.addAction("SET_ANALYTICS_ID", 
(state, action) => {
  return {...state, analyticsId: action.payload}
});
creatorPolicy.addAction(clear, () => {
  return {...init};
});

const {setLoading, setError, setData, setItemId, setPolicy, setAmountId, setPolicyId, setReportId, setAnalyticsId} = creatorPolicy.createActions();
const itemsReducer = creatorPolicy.createReducerFetch(init);

const loadPolicies = (type: "epolicy" | "greencard") => async (dispatch: any, getState: any) => {
  dispatch(setLoading(true));
  const state = getState() as RootState;

  const setCustomerCategory = () => {
    if (getPrivilege(state)) {
        return "PRIVILEGED";
    }

    return "NATURAL";
  };

  const getEpolicyCalculatorOfferData = (vehicleType) => {
    const data: IDataOffer = {
      salePoint: getSalePointId(state),
      customerCategory: setCustomerCategory(),
      franchise: null,
      bonusMalus: null,
      taxi: getIsTaxi(state),
      autoCategory: vehicleType ? vehicleType : "",
      discount: 0,
      registrationPlace: getRightCityId(state),
      outsideUkraine: getIsForeign(state),
      usageMonths: 0,
      dateFrom: getCorrectDate(getDay()),
      driveExp: false,
      dateTo: getFinishToDate(),
      registrationType: isVehicleOtkRequired(getAutoKind(state), vehicleType) ? "PERMANENT_WITH_OTK" : "PERMANENT_WITHOUT_OTK",
      otkDate: getOtkDate(state)
    };

    return data;
  };

  const getEpolicyNumberOfferData = () => {
    const data: IDataNumberOffer = {
      customerCategory: setCustomerCategory(),
      dateFrom: getCorrectDate(getDay()),
      stateNumber: state.vehicle.data!.regNumber,
      registrationType: isVehicleOtkRequired(state.vehicle.data!.kind, state.vehicle.data!.type) ? "PERMANENT_WITH_OTK" : "PERMANENT_WITHOUT_OTK",
      taxi: getIsTaxi(state),
      otkDate: getOtkDate(state),
      calculateExternalTariff: false
    };

    return data;
  };

  const getGreencardOfferData = () => {
    const data: IDataGreencardOffer = {
      salePoint: getSalePointId(state),
      customerCategory: setCustomerCategory(),
      dateFrom: getCorrectDate(getDay()),
      area: getCoverageArea(state).value,
      type: getVehicleType(state).code,
      period: getPeriod(state).code
    };

    return data;
  };

  try {
    let res: ICompanyInsurance[] = [];
    let data: any = {};

    if (type === "epolicy") {
        let vehicleType = getCalculatorAutoType(state);
        
        if (vehicleType) {
            data = getEpolicyCalculatorOfferData(vehicleType);
            res = await serviceOsago.getOffers(type, data);
        } else {
            if (state.vehicle.data?.registrationPlace?.id && state.vehicle.data?.registrationPlace?.name) {
                data = getEpolicyNumberOfferData();
                res = await serviceOsago.getOffersByNumber(dispatch, data);
            } else {
                vehicleType = getVehicleAutoType(state);
                data = getEpolicyCalculatorOfferData(vehicleType);
                res = await serviceOsago.getOffers(type, data);
            }
        }
    } else {
        data = getGreencardOfferData();
        res = await serviceOsago.getOffers(type, data);
    }

    dispatch(setData(res));
  } catch (e) {
    console.error(">>> ERROR: ", e);
    dispatch(setError(e));
  } finally {
    dispatch(setLoading(false));
  }
};

export {loadPolicies, setDataOrder, setGreenCardDataOrder, setItemId, setAmountId, setPolicyId, setPolicy, setReportId, setAnalyticsId};
export default combineReducers({
  dataOrder: dataOrderReducer,
  policies: itemsReducer,
  greenCardDataOrder: greenCardDataOrderReducer
});
