import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {fetchSomeDataIfNeeded} from "../redux-data/some/someAction";
import {useLocation, NavLink} from "react-router-dom";
import {hasInsurance, isSettingItemActive} from "../utils/utilsGeneral";
import {clear} from "../redux-data/token/tokenReducer";
import webConfig from "../config";
import {getCurrentUser, getCurrentUserInfo} from "../redux-data/user/userReducer";
import MstbuPolicyValidation from "../utils/MstbuPolicyValidation";

export const fetchData = ({store}: any) => {
  return store.dispatch(fetchSomeDataIfNeeded());
};

const MainComponent: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const correctInsurance = hasInsurance(props.match.params.name);
  const currentUser = useSelector(getCurrentUser);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const [isDevModeEnabled, setDevModeEnabled] = useState(false);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/auto") {
        dispatch(clear());
    }
  }, [location, dispatch]);

  useEffect(() => {
    const checkDevModeState = async () => {
      const devModeState = await isSettingItemActive(webConfig.enableDevModeID);
      setDevModeEnabled(devModeState);
    };

    checkDevModeState();
  }, []);
 
  return (
  <div className={`home-page${correctInsurance ? "-step" : ""}`}>
   <main className="section-main">
        <div className="container">
          <div className="row">
            <div className="main-container">
              <article className="main-block">
                <h1>Надійний сервіс онлайн страхування</h1>
                <p>У нас Ви можете розрахувати вартість і придбати поліс страхування за найкращою ціною</p>
              </article>
              <div className="insurance-wrapper">
                  <div className="insurance-container">
                    {isDevModeEnabled && currentUser && currentUserInfo && currentUser.sessionId && !currentUserInfo?.permissions.salePointAdmin ?
                      <div className="insurance-nav">
                         <div className="dev-mode-notification"><i className="fa fa-cog fa-spin dev-mode-notification-icon"></i>Тривають технічні роботи на сайті. Сервіс буде відновлено найближчим часом.</div>
                         <MstbuPolicyValidation link={webConfig.mtsbuPolicyValidationLink} />
                      </div>
                     :
                      <div className="insurance-nav">
                        <NavLink to="/epolicy" className="btn btn-link btn-white-border">Придбати Автоцивілку</NavLink>
                        <NavLink to="/greencard" className="btn btn-link btn-white-border">Придбати Зелену картку</NavLink>
                        <div className="click-notification">&uarr; Вибрати &uarr;</div>
                        <MstbuPolicyValidation link={webConfig.mtsbuPolicyValidationLink} />
                      </div>
                    }
                  </div>
              </div>
            </div>
            <div className="info-container">
              <h1>Тут зручний пошук страхових та фінансових продуктів</h1>
              <p><i className="fa-light fa-circle-check notification-icon"></i>Тільки перевірені страхові компанії України</p>
              <p><img className="mtsbu-icon" src={require("../assets/img/logos/mtsbu.svg")} alt="mtsbu" />Гарантія реєстрації МТСБУ</p>
              <p><i className="fa-sharp fa-light fa-shield-check notification-icon"></i>У нас є вибір більш як 20 страхових компаній</p>
              <p><i className="fa-sharp fa-light fa-envelope-circle-check notification-icon"></i>Гарантовано Ви отримаєте поліс страхування реєстрації МТСБУ на Ваш E-mail</p>
            </div>
          </div>
        </div>
      </main>
     <section className="main-body">
       <div className="main-wrapper">
        <div className="main-content-container">
          <div className="main-info-container">
            <p>Ми працюємо з багатьма страховими компаніями</p>
            <p>Якщо ти вмієш заощаджувати то тобі сюди</p>
          </div>
          <div className="companies-container">
              <figure className="logo-one">
                <img src={require("../assets/img/logos/1.webp")} width="72px" height="22px" loading="lazy" alt="VUSO"/>
                <img src={require("../assets/img/logos/2.webp")} width="72px" height="16px" loading="lazy" alt="Credo"/>
                <img src={require("../assets/img/logos/3.webp")} width="72px" height="30px" loading="lazy" alt='Страхова компанія "Велтлінер"'/>
                <img src={require("../assets/img/logos/4.webp")} width="72px" height="24px" loading="lazy" alt='Страхова група "Оберіг"'/>
                <img src={require("../assets/img/logos/28.webp")} width="72px" height="22px" loading="lazy" alt="Оранта"/>
                <img src={require("../assets/img/logos/7.webp")} width="72px" height="60px" loading="lazy" alt='Страхова компанія "Salamandra"'/>
                <img src={require("../assets/img/logos/8.webp")} width="72px" height="72px" loading="lazy" alt="PZU"/>
                <img src={require("../assets/img/logos/9.webp")} width="72px" height="16px" loading="lazy" alt='Страхова компанія "UPSK"'/>
                <img src={require("../assets/img/logos/30.webp")} width="72px" height="30px" loading="lazy" alt="Garant Assistant"/>
                <img src={require("../assets/img/logos/11.webp")} width="72px" height="44px" loading="lazy" alt='Страхова компанія "Мотор-Гарант"'/>
                <img src={require("../assets/img/logos/13.webp")} width="72px" height="20px" loading="lazy" alt='Страхова компанія "Респект"'/>
                <img src={require("../assets/img/logos/15.webp")} width="72px" height="20px" loading="lazy" alt="Страхова компанія Universalna"/>
                <img src={require("../assets/img/logos/16.webp")} width="72px" height="28px" loading="lazy" alt="ЄТС"/>
                <img src={require("../assets/img/logos/17.webp")} width="72px" height="42px" loading="lazy" alt="ARX"/>
                <img src={require("../assets/img/logos/18.webp")} width="72px" height="72px" loading="lazy" alt="UNIQA Страхування"/>
              </figure>
            </div>
       </div>
      </div>
     </section>
    </div>
  );
};

// export default MainComponent;
const mapStateToProps = (state: any) => ({
  someData: state.someData
});

export default connect(mapStateToProps)(MainComponent);
