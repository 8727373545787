import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams, Link} from "react-router-dom";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {
    checkAuthToken, 
    getInsuranceType, 
    checkEditAction, 
    clearCardNumber, 
    checkValue, 
    getPolicyStatus, 
    refreshPage, 
    navToAdminPanel, 
    navToReportsPage, 
    navToReportsEpolicyPage, 
    navToReportsGreencardPage, 
    removeWhitespace, 
    checkRequestStatus
} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import CustomSelect from "../../../components/common/CustomSelect";
import Switch from "react-switch";
import {insuranceTypes, allPolicyStatus} from "../../../mock-data/insuranceTypes";
import {formatDBDateTime} from "../../../utils/utilsDate";
import {IReport} from "../../../redux-data/report/reportTypes";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import useForm from "react-hook-form";
import CustomField from "../../../components/MainPage/CustomField";
import PolicyStatus from "../../../components/Forms/common/PolicyStatus";
import {StatusOffer, validateOfferStatus} from "../../../redux-data/offer/offerReducer";
import serviceOsago from "../../../service/serviceOsago";
import webConfig from "../../../config";
import PolicyExpirationDate from "../../../components/Forms/common/PolicyExpirationDate";
import creditCardType from "credit-card-type";

const ManageReportPage = (props) => {
    const dashboardHistory = useHistory();
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const {id, externalID, insuranceType, phoneNumber, vehicleNumber, reportID, action} = useParams<{
        id: string;
        externalID: string;
        insuranceType: string;
        phoneNumber: string; 
        vehicleNumber: string;
        reportID: string;
        action: string;
    }>();
    const [currentInsuranceType, setCurrentInsuranceType] = useState(getInsuranceType(insuranceType));
    const [authInProgress, setAuthInProgress] = useState(false);
    const [isEditMode, setEditMode] = useState(checkEditAction(action));
    const [cardNumber, setCardNumber] = useState("");
    const [cardType, setCardType] = useState("");
    const [isPrivilege, setPrivilege] = useState(false);
    const [isTaxi, setTaxi] = useState(false);
    const [isPromocode, setPromocode] = useState(false);
    const [isForeign, setForeign] = useState(false);
    const [isCashback, setCashback] = useState(false);
    const [currentPolicyStatus, setCurrentPolicyStatus] = useState("");
    const [updatedPolicyStatus, setUpdatedPolicyStatus] = useState({
        value: "",
        label: "",
        disabled: false
    });
    const [customerID, setCustomerID] = useState(0);
    const [vehicleID, setVehicleID] = useState(0);
    const [reportErrorMsg, setReportErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [isPolicyStatusUpdated, setPolicyStatusUpdated] = useState(false);
    const [isCardNumberUpdated, setCardNumberUpdated] = useState(false);
    const [policyStatusUpdateInProgress, setPolicyStatusUpdateInProgress] = useState(false);
    const [report, setReport] = useState<IReport>({
        insurerName: "",
        franchise: "",
        price: "",
        discount: "",
        totalAmount: "",
        isPrivilege: false,
        isTaxi: false,
        isPromocode: false,
        isForeign: false,
        creationDate: "",
        fromDate: "",
        expirationDate: "",
        customerPhoneNumber: "",
        vehicleStateNumber: "",
        insuranceType: "",
        isCashback: false,
        cashbackAmount: "",
        policyStatus: "",
        policyPeriod: "",
        policyCoverageArea: "",
        offerID: "",
        cardNumber: ""
    });

    const {register, errors, clearError} = useForm<any>({
        reValidateMode: "onChange",
        mode: "onChange",
        nativeValidation: false
    });

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken()) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardHistory.push("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardHistory.push("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        fetchReport();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const fetchReport = async () => {
        try {
            const reportResponse = await dbAxiosInstance.get(`/report/${encodeURIComponent(phoneNumber)}/${encodeURIComponent(vehicleNumber.toUpperCase())}/${encodeURIComponent(reportID)}`);
            const report = reportResponse.data[0];

            const customerResponse = await dbAxiosInstance.get(`/admin/customer/${encodeURIComponent(phoneNumber)}/${encodeURIComponent(vehicleNumber.toUpperCase())}/${encodeURIComponent(currentInsuranceType.value)}`);
            const customer = customerResponse.data[0];

            const vehicleResponse = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(vehicleNumber.toUpperCase())}`);
            const vehicle = vehicleResponse.data[0];

            if (report) {
                setReport({insurerName: report.insurer_name, franchise: report.franchise, price: report.price, discount: report.discount, totalAmount: report.total_amount, isPrivilege: report.is_privilege, isTaxi: report.is_taxi, 
                           isPromocode: report.is_promocode, isForeign: report.is_foreign, creationDate: report.creation_date, fromDate: report.from_date, expirationDate: report.expiration_date, customerPhoneNumber: report.customer_phone_number, 
                           vehicleStateNumber: report.vehicle_state_number, insuranceType: report.insurance_type, isCashback: report.is_cashback, cashbackAmount: report.cashback_amount, policyStatus: report.policy_status, 
                           policyPeriod: report.policy_period, policyCoverageArea: report.policy_coverage_area, offerID: report.offer_id, cardNumber: report.card_number});
                setCurrentInsuranceType(getInsuranceType(report.insurance_type));
                setCurrentPolicyStatus(report.policy_status);
                setUpdatedPolicyStatus(getPolicyStatus(report.policy_status));
                setCardNumber(report.card_number);

                if (report.card_number) {
                    setCardType(creditCardType(report.card_number)[0]?.type);
                } else {
                    setCardType("");
                }
                
                setPrivilege(report.is_privilege);
                setTaxi(report.is_taxi);
                setPromocode(report.is_promocode);
                setForeign(report.is_foreign);
                setCashback(report.is_cashback);
                setReportErrorMsg("");
            } else {
                setReport({insurerName: "", franchise: "", price: "", discount: "", totalAmount: "", isPrivilege: false, isTaxi: false, isPromocode: false, isForeign: false, creationDate: "", fromDate: "", expirationDate: "", customerPhoneNumber: "", 
                           vehicleStateNumber: "", insuranceType: "", isCashback: false, cashbackAmount: "", policyStatus: "", policyPeriod: "", policyCoverageArea: "", offerID: "", cardNumber: ""});
                setCardNumber("");
                setCardType("");
                setPrivilege(false);
                setTaxi(false);
                setPromocode(false);
                setForeign(false);
                setCashback(false);
                setReportErrorMsg("Помилка БД! Не вдалося завантажити дані звіту.");
            }

            if (customer) {
                setCustomerID(customer.id);
                setReportErrorMsg("");
            } else {
                setCustomerID(0);
                setReportErrorMsg("Помилка БД! Не вдалося завантажити дані клієнта.");
            }

            if (vehicle) {
                setVehicleID(vehicle.id);
                setReportErrorMsg("");
            } else {
                setVehicleID(0);
                setReportErrorMsg("Помилка БД! Не вдалося завантажити дані ТЗ.");
            }
        } catch (error: any) {
            setReport({insurerName: "", franchise: "", price: "", discount: "", totalAmount: "", isPrivilege: false, isTaxi: false, isPromocode: false, isForeign: false, creationDate: "", fromDate: "", expirationDate: "", customerPhoneNumber: "", 
                       vehicleStateNumber: "", insuranceType: "", isCashback: false, cashbackAmount: "", policyStatus: "", policyPeriod: "", policyCoverageArea: "", offerID: "", cardNumber: ""});
            setCardNumber("");
            setCardType("");
            setPrivilege(false);
            setTaxi(false);
            setPromocode(false);
            setForeign(false);
            setCashback(false);
            setReportErrorMsg("Помилка БД! Не вдалося завантажити дані звіту, клієнта і ТЗ.");
        }
    };
    
    const handleEditModeChange = (mode) => {
        setEditMode(mode);
    };

    const handleInsuranceTypesChange = (type: any) => {
        if (insuranceType === type.value && report.insuranceType === type.value) {
            setCurrentInsuranceType(type);
            setReport((prev) => ({...prev, insuranceType: type.value}));
        }
    };

    const handleCardNumberChange = (event) => {
        const clearNum = clearCardNumber(event.target.value);
        event.target.value = `${clearNum.slice(0, 4)} ${clearNum.slice(4, 8)} ${clearNum.slice(8, 12)} ${clearNum.slice(12, 19)}`.trim();
        const type = creditCardType(event.target.value)[0]?.type;

        if (type && event.target.value.length > 0) {
            setCardType(type);
        } else {
            setCardType("");
        }

        setCardNumber(event.target.value);

        if (removeWhitespace(event.target.value).length === Number(webConfig.customerCardNumLength)) {
            setReport((prev) => ({...prev, cardNumber: checkValue(event.target.value)}));
            setCardNumberUpdated(true);
        } else {
            setCardNumberUpdated(false);
        }
    };

    const handlePolicyStatusChange = (status: any) => {
        setUpdatedPolicyStatus(status);
        setReport((prev) => ({...prev, policyStatus: status.value}));
        setPolicyStatusUpdated(true);
    };

    const handleInsurerNameChange = (event) => {
        setReport((prev) => ({...prev, insurerName: checkValue(event.target.value)}));
    };

    const handleFranchiseChange = (event) => {
        setReport((prev) => ({...prev, franchise: checkValue(event.target.value)}));
    };

    const handlePriceChange = (event) => {
        setReport((prev) => ({...prev, price: checkValue(event.target.value)}));
    };

    const handleDiscountChange = (event) => {
        setReport((prev) => ({...prev, discount: checkValue(event.target.value)}));
    };

    const handleTotalAmountChange = (event) => {
        setReport((prev) => ({...prev, totalAmount: checkValue(event.target.value)}));
    };

    const handlePrivilegeStateChange = (state) => {
        setPrivilege(state);
        setReport((prev) => ({...prev, isPrivilege: state}));
    };

    const handleTaxiStateChange = (state) => {
        setTaxi(state);
        setReport((prev) => ({...prev, isTaxi: state}));
    };

    const handlePromocodeStateChange = (state) => {
        setPromocode(state);
        setReport((prev) => ({...prev, isPromocode: state}));
    };

    const handleForeignStateChange = (state) => {
        setForeign(state);
        setReport((prev) => ({...prev, isForeign: state}));
    };

    const handleFromDateChange = (name, date) => {
        setReport((prev) => ({...prev, fromDate: checkValue(date)}));
        clearError(name);
    };

    const handleExpirationDateChange = (name, date) => {
        setReport((prev) => ({...prev, expirationDate: checkValue(date)}));
        clearError(name);
    };

    const handlePolicyPeriodChange = (event) => {
        setReport((prev) => ({...prev, policyPeriod: checkValue(event.target.value)}));
    };

    const handleCustomerPhoneNumberChange = (event) => {
        setReport((prev) => ({...prev, customerPhoneNumber: checkValue(event.target.value)}));
    };

    const viewCustomerInfo = (event) => {
        if (customerID > 0) {
            dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/customer/${currentInsuranceType.value}/${phoneNumber}/${customerID}/view`});
        } else {
            setErrorNotificationHidden(false);
            setReportErrorMsg("Помилка БД! Не вдалося завантажити дані клієнта.");
        }
    };

    const handlePolicyCoverageAreaChange = (event) => {
        setReport((prev) => ({...prev, policyCoverageArea: checkValue(event.target.value)}));
    };

    const handleVehicleStateNumberChange = (event) => {
        setReport((prev) => ({...prev, vehicleStateNumber: checkValue(event.target.value)}));
    };

    const viewVehicleInfo = (event) => {
        if (vehicleID > 0) {
            dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/view-vehicle/${vehicleNumber.toLowerCase()}/${vehicleID}`});
        } else {
            setErrorNotificationHidden(false);
            setReportErrorMsg("Помилка БД! Не вдалося завантажити дані ТЗ.");
        }
    };

    const handleOfferIDChange = (event) => {
        setReport((prev) => ({...prev, offerID: checkValue(event.target.value)}));
    };

    const handleCashbackStateChange = (state) => {
        setCashback(state);
        setReport((prev) => ({...prev, isCashback: state}));
    };

    const handleCashbackAmountChange = (event) => {
        setReport((prev) => ({...prev, cashbackAmount: checkValue(event.target.value)}));
    };

    const deleteReport = async (id) => {
        try {
            await dbAxiosInstance.delete(`/report/${encodeURIComponent(id)}`);
            dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/reports/${insuranceType}`});
        } catch (error: any) {
            const userDataError = error.toJSON();
            setReportErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setReport({insurerName: "", franchise: "", price: "", discount: "", totalAmount: "", isPrivilege: false, isTaxi: false, isPromocode: false, isForeign: false, creationDate: "", fromDate: "", expirationDate: "", customerPhoneNumber: "", 
                       vehicleStateNumber: "", insuranceType: "", isCashback: false, cashbackAmount: "", policyStatus: "", policyPeriod: "", policyCoverageArea: "", offerID: "", cardNumber: ""});
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const handleReportEdit = async (event) => {
        try {
            event.preventDefault();

            if (report) {
                if (isCardNumberUpdated && removeWhitespace(report.cardNumber!).length === Number(webConfig.customerCardNumLength)) {
                    const dbReportCardRes = await dbAxiosInstance.put(`/admin/update-report/card-number/${encodeURIComponent(reportID)}`, {cardNumber: report.cardNumber});
                    const dbReportCardMsg = dbReportCardRes.data;
                    setCardNumberUpdated(false);
                    dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/report/${currentInsuranceType.value}/${phoneNumber}/${vehicleNumber}/${reportID}/item/edited`, state: {
                        isSuccess: checkRequestStatus(dbReportCardRes.statusText),
                        status: dbReportCardRes.status,
                        successMsg: dbReportCardMsg,
                        errorMsg: ""
                    }});
                }

                if (isPolicyStatusUpdated) {
                    if (report.policyStatus == validateOfferStatus(StatusOffer.EMITTED)) {
                        setPolicyStatusUpdateInProgress(true);
                        const euaRes = await serviceOsago.manageOffer(report.offerID, report.policyStatus);

                        if (euaRes.success) {
                            const dbReportStatusRes = await dbAxiosInstance.put(`/update-report/status/${encodeURIComponent(reportID)}`, {offerStatus: report.policyStatus});
                            dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/report/${currentInsuranceType.value}/${phoneNumber}/${vehicleNumber}/${reportID}/item/edited`, state: {
                                isSuccess: checkRequestStatus(dbReportStatusRes.statusText),
                                successMsg: `Договір №${euaRes.data.number} успішно укладено. Ідентифікатор договору: ${euaRes.data.id}`,
                                errorMsg: `Помилка! Не вдалось укласти договір. Код помилки: ${euaRes.code}`
                            }});
                        } else {
                            dashboardHistory.push({pathname: `/admin/dashboard/${id}/${externalID}/report/${currentInsuranceType.value}/${phoneNumber}/${vehicleNumber}/${reportID}/item/edited`, state: {
                                isSuccess: false,
                                successMsg: "",
                                errorMsg: `Помилка! Не вдалось укласти договір. Код помилки: ${euaRes.code}`
                            }});
                        }
                    } else {
                        setReportErrorMsg("Помилка! Договір може бути змінено тільки на статус - Укладений.");
                        setErrorNotificationHidden(false);
                    }

                    setPolicyStatusUpdated(false);
                    setPolicyStatusUpdateInProgress(false);
                }

                if (!isCardNumberUpdated && !isPolicyStatusUpdated) {
                    setReportErrorMsg("Помилка! Для редагування звіту виберіть статус або вкажіть номер карти клієнта.");
                    setErrorNotificationHidden(false);
                }
            } else {
                setReportErrorMsg("Будь ласка, заповніть усі необхідні поля, щоб оновити дані звіту!");
                setErrorNotificationHidden(false);
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setReportErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setErrorNotificationHidden(false);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={dashboardHistory.goBack}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp;
                            <CustomTooltip msg="Перейти до Звітів"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/reports`}}>Звіти</Link></CustomTooltip> &laquo;&nbsp; 
                            <CustomTooltip msg={`Перейти до ${currentInsuranceType.label}`}><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/reports/${currentInsuranceType.value}`}}>{currentInsuranceType.label}</Link></CustomTooltip> &laquo; {vehicleNumber.toUpperCase()}</h1>
                         {policyStatusUpdateInProgress ?
                            <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={policyStatusUpdateInProgress} infoMsg="Триває оновлення статусу поліса" />                     
                          :
                            report ?
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="switch-container">
                                                <label>
                                                    <Switch onChange={handleEditModeChange} checked={isEditMode} onColor="#1BA876" />
                                                    <div className="switch-text">Дозволити редагування</div>
                                                </label>
                                            </div>
                                            <p className="item-title">Створено: <span className="font-bold">{formatDBDateTime(report.creationDate)}</span></p>
                                            <p className="item-title">Поліс: <PolicyStatus status={currentPolicyStatus} /></p>
                                            {currentPolicyStatus === validateOfferStatus(StatusOffer.EMITTED) && <p className="item-title">Залишилось: <PolicyExpirationDate date={report.expirationDate} isStyle={true} /></p>}
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Тип страхування</label>
                                                <CustomSelect
                                                    options={insuranceTypes}
                                                    handleChange={handleInsuranceTypesChange}
                                                    value={currentInsuranceType}
                                                    isCreatable={false}
                                                    isDisabled={true}
                                                    placeholder="Тип страхування"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    pattern: {
                                                        value: /[\d| ]{19}/,
                                                        message: "Введіть вірний номер карти"
                                                    },
                                                    maxLength: {
                                                        value: 19,
                                                        message: "Введіть вірний номер карти"
                                                    }
                                                })}
                                                errors={errors}
                                                name="card"
                                                label="Номер карти"
                                                defaultValue={cardNumber}
                                                readOnly={!isEditMode}
                                                placeholder="0000 0000 0000 0000"
                                                onChange={handleCardNumberChange}
                                            />
                                            <i className={`${cardType ? `fa-brands fa-cc-${cardType}` : "fa-regular fa-credit-card"} card-icon`}></i>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Статус поліса</label>
                                                <CustomSelect
                                                    options={allPolicyStatus}
                                                    handleChange={handlePolicyStatusChange}
                                                    value={updatedPolicyStatus}
                                                    isCreatable={false}
                                                    isDisabled={!isEditMode}
                                                    placeholder="Статус поліса"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="insurer_name"
                                                label="Страховик"
                                                defaultValue={report.insurerName}
                                                readOnly={true}
                                                onChange={handleInsurerNameChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="franchise"
                                                label="Франшиза"
                                                defaultValue={report.franchise}
                                                readOnly={true}
                                                onChange={handleFranchiseChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="price"
                                                label="Ціна (грн)"
                                                defaultValue={report.price}
                                                readOnly={true}
                                                onChange={handlePriceChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="discount"
                                                label="Знижка/Процент кешбеку (%)"
                                                defaultValue={report.discount}
                                                readOnly={true}
                                                onChange={handleDiscountChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="total_amount"
                                                label="Сума (грн)"
                                                defaultValue={report.totalAmount}
                                                readOnly={true}
                                                onChange={handleTotalAmountChange}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <div className="switch-container">
                                                <label>
                                                    <Switch onChange={handlePrivilegeStateChange} checked={isPrivilege} disabled={true} onColor="#1BA876" />
                                                    <div className="switch-text">Є пільга</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="switch-container">
                                                <label>
                                                    <Switch onChange={handleTaxiStateChange} checked={isTaxi} disabled={true} onColor="#1BA876" />
                                                    <div className="switch-text">Таксі</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="switch-container">
                                                <label>
                                                    <Switch onChange={handlePromocodeStateChange} checked={isPromocode} disabled={true} onColor="#1BA876" />
                                                    <div className="switch-text">Промокод</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="switch-container">
                                                <label>
                                                    <Switch onChange={handleForeignStateChange} checked={isForeign} disabled={true} onColor="#1BA876" />
                                                    <div className="switch-text">Iноземна</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="from_date"
                                                label="Початок дії полісу"
                                                type="date"
                                                placeholder="РРРР.ММ.ДД"
                                                autoComplete="off"
                                                dateReadOnly={true}
                                                dateValue={report.fromDate}
                                                onDateChange={handleFromDateChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="expiration_date"
                                                label="Завершення дії полісу"
                                                type="date"
                                                placeholder="РРРР.ММ.ДД"
                                                autoComplete="off"
                                                dateReadOnly={true}
                                                dateValue={report.expirationDate}
                                                onDateChange={handleExpirationDateChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="policy_period"
                                                label="Період полісу"
                                                defaultValue={report.policyPeriod}
                                                readOnly={true}
                                                onChange={handlePolicyPeriodChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="customer_phone_number"
                                                label="Номер клієнта"
                                                defaultValue={report.customerPhoneNumber}
                                                readOnly={true}
                                                onChange={handleCustomerPhoneNumberChange}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Деталі клієнта</label>
                                                <input type="button" className="view-customer-info-btn" onClick={viewCustomerInfo} value="Переглянути" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="policy_coverage_area"
                                                label="Територія покриття"
                                                defaultValue={report.policyCoverageArea}
                                                readOnly={true}
                                                onChange={handlePolicyCoverageAreaChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="vehicle_state_number"
                                                label="Державний номер ТЗ"
                                                defaultValue={report.vehicleStateNumber}
                                                readOnly={true}
                                                onChange={handleVehicleStateNumberChange}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Деталі ТЗ</label>
                                                <input type="button" className="view-vehicle-info-btn" onClick={viewVehicleInfo} value="Переглянути" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="switch-container">
                                                <label>
                                                    <Switch onChange={handleCashbackStateChange} checked={isCashback} disabled={true} onColor="#1BA876" />
                                                    <div className="switch-text">Кешбек</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="cashback_amount"
                                                label="Сума кешбеку (грн)"
                                                defaultValue={report.cashbackAmount}
                                                readOnly={true}
                                                onChange={handleCashbackAmountChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomField
                                                register={register({
                                                    required: "Це поле обов'язкове"
                                                })}
                                                errors={errors}
                                                name="offer_id"
                                                label="Індентифікатор замовлення"
                                                defaultValue={report.offerID}
                                                readOnly={true}
                                                onChange={handleOfferIDChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            {isEditMode && <button type="submit" className="edit-btn" onClick={handleReportEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>}
                                            <button type="button" className="admin-btn" onClick={() => deleteReport(reportID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                                            <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                                            <button type="button" className="admin-btn" onClick={() => navToReportsPage(dashboardHistory, id, externalID)}>Звіти</button>
                                            <button type="button" className="admin-btn" onClick={() => navToReportsEpolicyPage(dashboardHistory, id, externalID)}>Звіти (Автоцивілка)</button>
                                            <button type="button" className="admin-btn" onClick={() => navToReportsGreencardPage(dashboardHistory, id, externalID)}>Звіти (Зелена карта)</button>
                                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardHistory, id, externalID)}>Панель адміністратора</button>
                                        </div>
                                        {!hideErrorNotification && reportErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{reportErrorMsg}</div>}
                                    </div>
                                :
                                    <div>
                                        {!hideErrorNotification && reportErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{reportErrorMsg}</div>}
                                    </div>
                        }
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ManageReportPage;