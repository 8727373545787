import React from "react";
import ReactDOM from "react-dom";
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-owl-carousel2/lib/styles.css';
import './assets/css/select2.min.css';
import './assets/scss/styles.scss';
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {Base64} from "js-base64";
import configureStore from "./store";
import {configEuaAxios} from "./core/configEuaAxios";

// If provided by server, use it, else let the reducers handle initial state
// tslint:disable-next-line:no-any
const initialState = ((window as any).DATA !== null && (window as any).DATA !== "{{WINDOW_DATA}}") ? Base64.decode((window as any).DATA) : "{}";

const parsedInitialState = JSON.parse(initialState);
const {store, persistor} = configureStore(parsedInitialState);

configEuaAxios(store);

if (initialState === "{}") { // Non-server rendered.
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
} else { // Server rendered hydration
  ReactDOM.hydrate(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
}